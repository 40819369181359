<template>
  <div id="news">
    <!-- <vue-title title="Главная | Грузоперевозки KEL"></vue-title> -->
    <div class="carouselBg d-none d-sm-flex">
      <v-carousel :show-arrows="false" hide-delimiter-background height="200" hide-delimiters :cycle="true">
        <v-carousel-item :src="corusel1"></v-carousel-item>
        <v-carousel-item :src="corusel2"></v-carousel-item>
      </v-carousel>
    </div>
    <div id="online-orders" class="online-orders">
      <div class="filter-wrap pt-3 pb-3 companyInNumberBg">
        <div class="container">
          <div class="d-flex justify-space-between align-center">
            <div class="title">Новые грузы</div>
            <div class="total">
              Всего: <span class="text-primary">{{ cargos ? cargos.totals : 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-4">
        <div class="container">
          <div v-if="!cargos" class="mb-3">
            <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
            <span class="ml-4">Загрузка</span>
          </div>
          <div v-else>
            <div v-if="cargos.totals == 0">
              <strong><i class="fas fa-search mr-2"></i>Ничего не найдено.</strong>
            </div>
            <div class="row" v-if="cargos.totals > 0">
              <div class="col-12 col-lg-6 mb-4" v-for="cargoItem in cargos.items" :key="cargoItem.cargoLotId">
                <div class="card h-100 shadow-sm">
                  <div class="bg-primary" style="
                      border-top-left-radius: 0.25rem;
                      border-top-right-radius: 0.25rem;
                    ">
                    <div class="row">
                      <div class="col-4 text-left text-white py-1">
                        <small class="mb-2 pl-3">{{ cargoItem.cargoLotNumber }}</small>
                      </div>
                      <div class="col-4 text-center p-0">
                        <small class="bg-white mb-2 px-2 pb-1" style="
                            border-bottom-right-radius: 0.25rem;
                            padding-top: 0.3rem;
                            border-bottom-left-radius: 0.25rem;
                          "><span v-html="getStatus(cargoItem)"></span></small>
                      </div>
                      <div class="col-4 text-right text-white py-1">
                        <small class="mb-2 pr-3">{{ cargoItem.pickupDate || moment("DD.MM.YYYY") }}</small>
                      </div>
                    </div>
                    <div class="text-white px-3 pt-0 pb-2">
                      {{ cargoItem.cargo.descriptionOfCargo }}
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-2 col-sm-1">
                        <i class="fas fa-map-marked-alt font-20 text-muted"></i>
                      </div>
                      <div class="col-10 col-sm-11">
                        <span class="text-primary link-revert" @click="openRoute(cargoItem.cargoLotId)">{{
                          cargoItem.routeCaption }}</span>
                        <!-- <div class="modal fade" :id="'routeModal' + cargoItem.cargoLotId" tabindex="-1"
                          :aria-labelledby="'routeModalLabel' + cargoItem.cargoLotId" aria-hidden="true">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" :id="'routeModalLabel' + cargoItem.cargoLotId">
                                  Информация по маршруту
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <strong>Маршрут:</strong>
                                <p class="font-12">{{ cargoItem.routeCaption }}</p>
                                <strong>Адреса:</strong>
                                <div v-if="!selectRoute" class="mb-3">
                                  <v-progress-circular :size="40" :width="7" color="primary"
                                    indeterminate></v-progress-circular>
                                  Загрузка
                                </div>
                                <table v-if="selectRoute" class="table table-borderless font-12">
                                  <tbody>
                                    <tr v-for="(route, index) in selectRoute" :key="route.routePointId">
                                      <td class="pb-0">
                                        <div class="row">
                                          <div class="col-12 pb-3 d-flex align-items-center" v-if="index > 0">
                                            <i class="fas fa-long-arrow-alt-down text-yellow"
                                              style="font-size: 25px"></i><strong class="text-primary ml-3"
                                              v-if="route.distance > 0">{{ route.distance }} км</strong>
                                          </div>
                                          <div class="col-3 pr-0">
                                            <div v-if="route.operationType == 'rptLoading'"
                                              class="h-100 d-flex align-items-center">
                                              <i class="fas fa-arrow-right pr-1 text-danger"
                                                style="font-size: 20px"></i><i class="fas fa-truck-loading text-danger"
                                                style="font-size: 25px"></i>
                                            </div>
                                            <div v-if="route.operationType == 'rtpUnloading'"
                                              class="h-100 d-flex align-items-center">
                                              <i class="fas fa-arrow-left pr-1 text-secondary"
                                                style="font-size: 20px"></i><i
                                                class="fas fa-truck-loading text-secondary" style="font-size: 25px"></i>
                                            </div>
                                          </div>
                                          <div class="col-9 pl-0">
                                            <span v-if="isViewTime(route.operationTime)">{{ route.operationTime ||
                                              moment("DD.MM.YYYY HH:mm") }}<br>{{ route.address.localityDisplay
                                              }}</span>
                                            <span v-if="!isViewTime(route.operationTime)">{{ route.operationTime ||
                                              moment("DD.MM.YYYY") }}<br>{{ route.address.localityDisplay }}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                                  Закрыть
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-2 col-sm-1">
                        <i class="fas fa-truck-moving font-20 text-muted"></i>
                      </div>
                      <div class="col-10 col-sm-11">
                        {{ cargoItem.cargo.rollingStockRequestDisplay }}
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-2 col-sm-1">
                        <i class="fas fa-exclamation-triangle font-20 text-warning"></i>
                      </div>
                      <div class="col-10 col-sm-11">
                        Особые условия: {{ cargoItem.cargo.additionCarriageTerm }}
                      </div>
                    </div>
                  </div>
                  <div class="cardFooter">
                    <div class="row">
                      <div class="col-6 col-sm-4">
                        <div class="mr-4">
                          <v-btn color="#2c5799" variant="outlined" size="small"
                            @click="openOffers(cargoItem.cargoLotId)">
                            ВСЕ ПРЕДЛОЖЕНИЯ
                          </v-btn>
                        </div>
                        <!-- <div class="modal fade" :id="'offersModal' + cargoItem.cargoLotId" tabindex="-1"
                          :aria-labelledby="'offersModalLabel' + cargoItem.cargoLotId" aria-hidden="true">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" :id="'offersModalLabel' + cargoItem.cargoLotId">
                                  Предложения
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div v-if="!selectOffers" class="mb-3">
                                  <v-progress-circular :size="40" :width="7" color="primary"
                                    indeterminate></v-progress-circular>
                                  Загрузка
                                </div>
                                <div v-if="selectOffers && selectOffers.length == 0">
                                  <div class="row">
                                    <div class="col-12">
                                      На данный момент предложений нет.
                                    </div>
                                  </div>
                                </div>
                                <table v-if="selectOffers && selectOffers.length > 0" class="table mb-0 font-12">
                                  <tbody>
                                    <tr v-for="(offer, index) in selectOffers" :key="offer.cargoOfferId">
                                      <td style="border: 0px; width: 5px" class="pl-1 pr-0">
                                        <i v-if="offer.status == 'oisApproved'"
                                          class="fas fa-check-circle text-success"></i>
                                      </td>
                                      <td style="border: 0px; width: 10px" class="pr-0">
                                        {{ index + 1 }}:
                                      </td>
                                      <td style="border: 0px; width: 130px">{{
                                        offer.timeCreate || moment("DD.MM.YYYY HH:mm") }}
                                      </td>
                                      <td style="border: 0px">
                                        {{ offer.rollingStockUnitTypeName }}
                                      </td>
                                      <td class="text-right" style="border: 0px; width: 120px"><strong>{{
                                        offer.amount || formatMoney }} {{ offer.currencyShortName }}</strong></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                                  Закрыть
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="col-6 col-sm-4 text-right text-sm-center">
                        <div v-if="!cargoItem.agentOffer">
                          <v-btn color="#2c5799" variant="outlined" @click="this.dialogAuth = true" size="small"
                            v-if="!isLogged">
                            <i class="fas fa-file-invoice-dollar font-18 mr-1"></i>
                            ПРЕДЛОЖИТЬ ЦЕНУ
                          </v-btn>
                          <v-btn color="#2c5799" variant="outlined" size="small"
                            @click="openAddOfferModal(cargoItem.cargoLotId, cargoItem.rollingStockTypeId, cargoItem)"
                            v-else>
                            <i class="fas fa-file-invoice-dollar font-18 mr-1"></i>ПРЕДЛОЖИТЬ
                            ЦЕНУ
                          </v-btn>
                          <!-- <div class="modal fade" :id="'addOfferModal' + cargoItem.cargoLotId" tabindex="-1"
                            :aria-labelledby="'addOfferModalLabel' + cargoItem.cargoLotId" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                              <div class="modal-content">
                                <div class="modal-header text-left">
                                  <h5 class="modal-title" :id="'addOfferModalLabel' + cargoItem.cargoLotId">
                                    Добавить предложение:
                                    <strong>{{ cargoItem.cargoLotNumber }}</strong>
                                  </h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div v-if="errorMessageAddOffer" class="alert alert-danger mb-3" role="alert">
                                    <strong>Внимание!</strong> {{ errorMessageAddOffer }}
                                  </div>
                                  <DxForm :col-count="1" :form-data="offerNew" :label-location="labelLocation"
                                    :ref="'addOfferFormRef' + cargoItem.cargoLotId" :show-colon-after-label="true"
                                    :show-validation-summary="true" :disabled="isSendOffer">
                                    <DxGroupItem :colCount="3">
                                      <DxColCountByScreen :xs="1" :sm="1" />
                                      <DxSimpleItem
                                        :editor-options="{ value: offerNew.rollingStockUnitTypeId, searchEnabled: true, dataSource: storeRollingStockUnitTypes, displayExpr: 'rollingStockUnitTypeName', valueExpr: 'rollingStockUnitTypeId', showClearButton: true }"
                                        data-field="rollingStockUnitTypeId" editor-type="dxSelectBox">
                                        <DxLabel text="Тип ПС" />
                                        <DxRequiredRule message="Укажите Тип ПС" />
                                      </DxSimpleItem>
                                      <DxSimpleItem :editor-options="{ value: offerNew.amount }" data-field="amount"
                                        editor-type="dxNumberBox">
                                        <DxLabel text="Ваше предложение" />
                                        <DxRequiredRule />
                                      </DxSimpleItem>
                                      <DxSimpleItem
                                        :editor-options="{ value: offerNew.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true }"
                                        data-field="currencyId" editor-type="dxSelectBox">
                                        <DxLabel text="Валюта" />
                                        <DxRequiredRule message="Укажите Валюту" />
                                      </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxSimpleItem :editor-options="{ value: offerNew.note }" data-field="note"
                                      editor-type="dxTextBox">
                                      <DxLabel text="Комментарий" />
                                    </DxSimpleItem>
                                  </DxForm>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-primary" @click="AddOffer(cargoItem.cargoLotId)"
                                    :disabled="isSendOffer">
                                    Сохранить
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> -->
                        </div>
                        <div v-if="cargoItem.agentOffer">
                          <v-btn color="#2c5799" variant="outlined"
                            @click="openEditOfferModal(cargoItem.cargoLotId, cargoItem.rollingStockTypeId, cargoItem.agentOffer, cargoItem)"
                            size="small" v-if="!isLogged">
                            <i class="fas fa-file-invoice-dollar font-18 mr-1"></i>
                            ИЗМЕНИТЬ ЦЕНУ
                          </v-btn>
                          <div class="modal fade" :id="'editOfferModal' + cargoItem.cargoLotId" tabindex="-1"
                            :aria-labelledby="'editOfferModalLabel' + cargoItem.cargoLotId
                              " aria-hidden="true">
                            <!-- <div class="modal-dialog modal-lg">
                              <div class="modal-content">
                                <div class="modal-header text-left">
                                  <h5 class="modal-title" :id="'editOfferModalLabel' + cargoItem.cargoLotId">
                                    Изменить предложение:
                                    <strong>{{ cargoItem.cargoLotNumber }}</strong>
                                  </h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div v-if="errorMessageEditOffer" class="alert alert-danger mb-3" role="alert">
                                    <strong>Внимание!</strong> {{ errorMessageEditOffer }}
                                  </div>
                                  <DxForm :col-count="1" :form-data="offerEdit" :label-location="labelLocation"
                                    :ref="'editOfferFormRef' + cargoItem.cargoLotId" :show-colon-after-label="true"
                                    :show-validation-summary="true" :disabled="isSendOffer">
                                    <DxGroupItem :colCount="3">
                                      <DxColCountByScreen :xs="1" :sm="1" />
                                      <DxSimpleItem :editor-options="{
                                        value: offerEdit.rollingStockUnitTypeId,
                                        dataSource: storeRollingStockUnitTypes,
                                        displayExpr: 'rollingStockUnitTypeName',
                                        valueExpr: 'rollingStockUnitTypeId',
                                        showClearButton: true,
                                        showDataBeforeSearch: false,
                                      }" data-field="rollingStockUnitTypeId" editor-type="dxSelectBox">
                                        <DxLabel text="Тип ПС" />
                                        <DxRequiredRule message="Укажите Тип ПС" />
                                      </DxSimpleItem>
                                      <DxSimpleItem :editor-options="{ value: offerEdit.amount }" data-field="amount"
                                        editor-type="dxNumberBox">
                                        <DxLabel text="Новое предложение" />
                                        <DxRequiredRule />
                                      </DxSimpleItem>
                                      <DxSimpleItem :editor-options="{
                                        value: offerEdit.currencyId,
                                        items: currencys,
                                        displayExpr: 'сurrencyName',
                                        valueExpr: 'сurrencyId',
                                        showClearButton: true,
                                        searchEnabled: true,
                                      }" data-field="currencyId" editor-type="dxSelectBox">
                                        <DxLabel text="Валюта" />
                                        <DxRequiredRule message="Укажите Валюту" />
                                      </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxSimpleItem :editor-options="{ value: offerEdit.note }" data-field="note"
                                      editor-type="dxTextBox">
                                      <DxLabel text="Комментарий" />
                                    </DxSimpleItem>
                                  </DxForm>
                                </div>
                                <div class="modal-footer d-flex justify-content-between">
                                  <button type="button" class="btn btn-danger" @click="
                                    DelOffer(
                                      cargoItem.cargoLotId,
                                      cargoItem.agentOffer.cargoOfferId
                                    )
                                    ">
                                    Удалить
                                  </button>
                                  <div>
                                    <button type="button" class="btn btn-primary" @click="
                                      EditOffer(
                                        cargoItem.cargoLotId,
                                        cargoItem.agentOffer.cargoOfferId
                                      )
                                      " :disabled="isSendOffer">
                                      Сохранить
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div v-if="!cargoItem.agentOffer" class="col-12 col-sm-4 pt-2 pt-sm-1 text-right">
                        <span class="small text-muted">НЕТ ПРЕДЛОЖЕНИЯ</span>
                      </div>
                      <div v-if="cargoItem.agentOffer" class="col-12 col-sm-4 pt-2 pt-sm-1 text-right">
                        <!-- <span class="text-muted">{{ cargoItem.agentOffer.amount | formatMoney }} {{ cargoItem.agentOffer.currencyShortName }}</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <v-btn text="Показать еще" ariant="tonal" @click="$router.push({ name: 'Marketplace' })" />
          </div>
        </div>
      </div>
    </div>
    <div id="company-in-numbers" class="company-in-numbers">
      <div class="pt-5 pb-3 companyInNumberBg">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="title">Компания в цифрах</div>
          </div>
        </div>
      </div>
      <div class="bg-white py-4">
        <div class="container">
          <div class="companyInNumberMain">
            <div>
              <div v-for="(item, index) in companyInNumber" :key="index + 'companyInNumber'"
                class="companyInNumberBody mb-3">
                <img src="@/assets/images/company-in-numbers/confirmed.png" class="align-self-start mr-3 mt-3 img-48"
                  alt="8 лет на рынке международных грузовых автомобильных перевозок" />
                <div class="media-body">
                  <h3 class="mt-0 text-primary font-28 font-md-34 mb-1 companyInNumberTitle">
                    {{ item.title }}
                  </h3>
                  <p class="text-primary font-18">
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/company-in-numbers/volume-sales.png" class="companyInNumberMainImg mt-3"
                alt="Объем продаж за 2020 год составил 2,3 млрд тенге" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <div>
          <h5 class="modal-title" id="noLoginModalLabel">Вы не авторизованы</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="!isCabinetDisabled">
          Чтобы сделать/посмотреть предложение, вам нужно
          <div class="text-center">
            <v-btn text="войти" type="default" styling-mode="outlined" @click="openLR('Login')" class="my-3" />
          </div>
          <div class="text-center">или</div>
          <div class="text-center">
            <v-btn text="зарегистрироваться" type="default" styling-mode="outlined" @click="openLR('Registration')"
              class="my-3" />
          </div>
        </div>
        <div class="modal-body" v-else>
          <p>Для выполнения данных действий Вам требуется пройти регистрацию.</p>
          <p>
            По всем вопросам Вы можете связаться с нами по телефону:<br />
            +7 (727) 312-21-10 или jivochat
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Закрыть
          </button>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMakeOffer" width="auto">
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title">
            <span v-if="offerNew.type">Изменить</span>
            <span v-else>Добавить</span>
            предложение:
            <strong>{{ offerNew.cargoItem.cargoLotNumber }}</strong>
          </h5>
          <v-btn variant="text" @click="dialogMakeOffer = false">
            <span class="closeModalHeader" aria-hidden="true">&times;</span>
          </v-btn>
        </div>
        <div class="modal-body">
          <div v-if="errorMessageAddOffer" class="alert alert-danger mb-3" role="alert">
            <strong>Внимание!</strong> {{ errorMessageAddOffer }}
          </div>
          <v-form :disabled="isSendOffer">
            <v-card width="600">
              <div class="modalItem">
                <span class="mb-2">Тип ПС:</span>
                <v-select item-title="rollingStockUnitTypeName" item-value="rollingStockUnitTypeId" return-object
                  v-model="offerNew.rollingStockUnitTypeId" :items="rollingStockUnitTypes">
                </v-select>
              </div>
              <div class="modalItem">
                <span class="mb-2">Ваше предложение:</span>
                <v-text-field type="number" v-model="offerNew.amount"></v-text-field>
              </div>
              <div class="modalItem">
                <span class="mb-2">Валюта:</span>
                <v-select item-title="сurrencyName" item-value="currencyId" return-object v-model="offerNew.currencyId"
                  :items="currencys">
                </v-select>
              </div>
              <div class="modalItem">
                <span class="mb-2">Комментарий:</span>
                <v-textarea v-model="offerNew.note"></v-textarea>
              </div>
            </v-card>
          </v-form>
        </div>
        <div class="modal-footer">
          <v-btn v-if="offerNew.type == 1"
            @click="DelOffer(offerNew.cargoItem.cargoLotId, offerNew.cargoItem.agentOffer.cargoOfferId)"
            color="#e66454">
            Удалить
          </v-btn>
          <v-btn
            @click="offerNew.type == 1 ? EditOffer(offerNew.cargoItem.cargoLotId, offerNew.cargoItem.agentOffer.cargoOfferId) : AddOffer(offerNew.cargoItem.cargoLotId)"
            :disabled="isSendOffer" color="#00afef">Сохранить</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCargo" width="auto">
      <v-card width="500">
        <div class="modal-header">
          <h5 class="modal-title">Информация по маршруту</h5>
          <v-btn variant="text" @click="dialogCargo = false">
            <span class="closeModalHeader" aria-hidden="true">&times;</span>
          </v-btn>
        </div>
        <div class="modal-body">
          <strong>Маршрут:</strong>
          <p class="font-12 mb-4">{{ dialogCargoItem }}</p>
          <strong>Адреса:</strong>
          <div v-if="!selectRoute" class="mb-3">
            <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
            Загрузка
          </div>
          <table v-else class="table table-borderless font-12">
            <tbody>
              <tr v-for="(route, index) in selectRoute" :key="route.routePointId">
                <td class="pb-0">
                  <div class="row">
                    <div class="d-flex align-center mt-2 mb-2" v-if="index > 0">
                      <i class="fas fa-long-arrow-alt-down text-yellow" style="font-size: 25px"></i>
                      <strong class="text-primary ml-3" v-if="route.distance > 0">{{ route.distance }}
                        км</strong>
                    </div>
                    <div class="addressItem">
                      <div v-if="route.operationType == 'rptLoading'" class="h-100 d-flex align-items-center">
                        <i class="fas fa-arrow-right pr-1 text-danger" style="font-size: 20px"></i><i
                          class="fas fa-truck-loading text-danger" style="font-size: 25px"></i>
                      </div>
                      <div v-if="route.operationType == 'rtpUnloading'" class="h-100 d-flex align-items-center">
                        <i class="fas fa-arrow-left pr-1 text-secondary" style="font-size: 20px"></i><i
                          class="fas fa-truck-loading text-secondary" style="font-size: 25px"></i>
                      </div>
                      <div class="ml-6">
                        <span v-if="isViewTime(route.operationTime)">{{
                          route.operationTime ||
                          moment("DD.MM.YYYY HH:mm") }}<br>{{
                            route.address.localityDisplay
                          }}</span>
                        <span v-if="!isViewTime(route.operationTime)">{{
                          route.operationTime ||
                          moment("DD.MM.YYYY") }}<br>{{
                            route.address.localityDisplay
                          }}</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <v-btn @click="dialogCargo = false" color="#00afef" type="button">Закрыть</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOffer" width="auto">
      <v-card width="600">
        <div class="modal-header">
          <h5 class="modal-title">
            Предложения</h5>
          <v-btn variant="text" @click="dialogOffer = false">
            <span class="closeModalHeader" aria-hidden="true">&times;</span>
          </v-btn>
        </div>
        <div class="modal-body">
          <div v-if="!selectOffers" class="mb-3">
            <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
            Загрузка
          </div>
          <div v-if="selectOffers && selectOffers.length == 0">
            <div class="row">
              <div class="col-12">
                На данный момент предложений нет.
              </div>
            </div>
          </div>
          <table v-if="selectOffers && selectOffers.length > 0" class="table mb-0 font-12">
            <tbody>
              <tr v-for="(offer, index) in selectOffers" :key="offer.cargoOfferId">
                <td style="border: 0px; width: 5px;" class="pl-1 pr-0">
                  <i v-if="offer.status == 'oisApproved'" class="fas fa-check-circle text-success"></i>
                </td>
                <td style="border: 0px; width: 10px;" class="pr-0">{{
                  index + 1 }}:</td>
                <td style="border: 0px; width: 130px">{{
                  offer.timeCreate || moment("DD.MM.YYYY HH:mm") }}
                </td>
                <td style="border: 0px">{{
                  offer.rollingStockUnitTypeName }}
                </td>
                <td class="text-right" style="border: 0px; width: 120px"><strong>{{
                  offer.amount }} {{ offer.currencyShortName }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <v-btn @click="dialogOffer = false" color="#00afef" type="button">Закрыть</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import corusel1 from "../assets/images/sliders/home/1.jpg";
import corusel2 from "../assets/images/sliders/home/2.jpg";
// import LeftIcon from "vue-material-design-icons/ChevronLeft.vue";
// import RightIcon from "vue-material-design-icons/ChevronRight.vue";

// import { isCabinetDisabled } from '../../../environment/environment';
// import Vue from 'vue';
// import jQuery from 'jquery';

// let $ = jQuery;
// import {Header, Footer} from '../../shared/components';
// import headerRoutes from './header-routes';
// import localStore from './store';
// import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
// import DxButton from 'devextreme-vue/button';
import moment from "moment";
// import {mapGetters} from 'vuex';
// import CustomStore from 'devextreme/data/custom_store';
// import {httpClient} from '../../shared/services';
// import {
//     DxForm,
//     DxSimpleItem,
//     DxLabel,
//     DxRequiredRule,
//     DxGroupItem,
//     DxColCountByScreen,
// } from 'devextreme-vue/form';

// $(document).ready(function () {
//
// });

export default {
  components: {
    // LeftIcon,
    // RightIcon,
  },
  // name: 'Home',
  // components: {
  //     Header,
  //     Footer,
  //     DxLoadIndicator,
  //     DxButton,
  //     DxForm,
  //     DxSimpleItem,
  //     DxLabel,
  //     DxRequiredRule,
  //     DxGroupItem,
  //     DxColCountByScreen
  // },
  computed: {
    ...mapGetters({
      rollingStockUnitTypes: 'getRollingStockUnitTypes',
      cargos: "getCommonCargos",
      route: "getCommonRoute",
      currencys: 'getCurrencys',
    }),
  },
  data() {
    return {
      companyInNumber: [
        {
          title: "8 лет",
          text: "на рынке международных грузовых автомобильных перевозок",
        },
        {
          title: "Наша география",
          text: "страны Европы, СНГ, Турция, Китай, перевозки по всему Казахстану",
        },
        {
          title: "Свыше 100 Партнеров",
          text: "доверяют нам услуги грузоперевозок",
        },
        {
          title: "Свыше 15 000 рейсов",
          text: "мы осуществили",
        },
        {
          title: "свыше 300 000 тонн",
          text: "различных грузов мы перевезли",
        },
      ],
      corusel1,
      corusel2,
      dialog: false,
      //         isCabinetDisabled,
      //         headerRoutes,
      //         currencys: null,
      // cargos: null,
      skeep: 0,
      take: 6,
      dialogCargo: false,
      dialogOffer: false,
      orderField: "pickupDate",
      orderDesc: true,
      //         isSendOffer: false,
      //         errorMessageAddOffer: "",
      //         errorMessageEditOffer: "",
      dialogMakeOffer: false,
      offerNew: {
        type: 0,
        rollingStockUnitTypeId: "",
        amount: "",
        currencyId: "",
        cargoItem: null,
      },
      //         offerEdit: {
      //             rollingStockUnitTypeId: "",
      //             amount: "",
      //             currencyId: ""
      //         },
      //         labelLocation: 'top',
      //         storeRollingStockTypeId: "",
      //         // storeRollingStockUnitTypes: new CustomStore({
      //         //     key: 'rollingStockUnitTypeId',
      //         //     byKey: function () {
      //         //         //console.log(key);
      //         //         return {
      //         //             "rollingStockUnitTypeId": window.rollingStockUnitTypeId,
      //         //             "rollingStockUnitTypeName": window.rollingStockUnitTypeName
      //         //         };
      //         //     },
      //         //     load: (loadOptions) => {
      //         //         let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

      //         //         let filter = searchValue != '' ? `,{"rollingStockUnitTypeName":{"op":"con","value":"${searchValue}"}}` : '';

      //         //         return httpClient.get(`v2/Catalog/RollingStockUnitTypes?filter={"rollingStockTypeId":{"op":"eq","value":"${this.storeRollingStockTypeId}"}}${filter}`)
      //         //             .then((data) => {
      //         //                 return {
      //         //                     key: 'rollingStockUnitTypeId',
      //         //                     data: data.data.items,
      //         //                     totalCount: data.totals,
      //         //                     summary: data.totals,
      //         //                     groupCount: data.count,
      //         //                 };
      //         //             })
      //         //             .catch(() => {
      //         //                 throw 'Data Loading Error';
      //         //             });
      //         //     }
      //         // }),
      //         selectRoute: null,
      //         selectOffers: null
    };
  },
  mounted() {
    this.$store.dispatch("getCommonCargos", {
      order_field: this.orderField,
      order_desc: this.orderDesc,
      skeep: this.skeep,
      take: this.take,
    });
    this.getCurrencys();
    this.getCargos();
  },
  // mounted() {
  //     Vue.nextTick(function () {
  //         let leftArrowSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="7.365" height="12.92" viewBox="0 0 7.365 12.92"><path d="M97.4,7.1l5.556,5.555a.9.9,0,0,0,1.28-1.279L99.323,6.46l4.916-4.915a.9.9,0,0,0-1.28-1.28L97.4,5.82a.9.9,0,0,0,0,1.279Z" transform="translate(-97.139 0)" fill="#fff"></path></svg>';
  //         let rightArrowSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="7.365" height="12.92" viewBox="0 0 7.365 12.92"><path id="Контур_1771" data-name="Контур 1771" d="M104.239,7.1l-5.556,5.555a.9.9,0,0,1-1.28-1.279l4.916-4.916L97.4,1.544a.9.9,0,0,1,1.28-1.28l5.556,5.555a.9.9,0,0,1,0,1.279Z" transform="translate(-97.139 0)" fill="#fff"></path></svg>';

  //         // home-slider
  //         $('.home-slider .owl-carousel').owlCarousel({
  //             items: 1,
  //             nav: true,
  //             navText: [leftArrowSvg, rightArrowSvg],
  //             dots: true,
  //             loop: true,
  //             autoplay: true,
  //             autoplayTimeout: 5000,
  //             animateOut: 'fadeOut',
  //             animateIn: 'fadeIn'
  //         });

  //         // main-direction-carousel
  //         $('.main-direction-carousel .owl-carousel').owlCarousel({
  //             items: 3,
  //             responsive: {
  //                 0: {
  //                     items: 2,
  //                     drag: true,
  //                     mouseDrag: true
  //                 },
  //                 768: {
  //                     items: 3,
  //                     drag: false,
  //                     mouseDrag: false
  //                 },
  //                 992: {
  //                     items: 3,
  //                     drag: false,
  //                     mouseDrag: false
  //                 }
  //             },
  //             margin: 12,
  //             nav: false,
  //             navText: [leftArrowSvg, rightArrowSvg],
  //             dots: false,
  //             loop: false,
  //             autoplay: false
  //         });

  //         // home direction hover card event
  //         $('section .direction-item').hover(function () {
  //             $(this).addClass('hover')
  //         }, function () {
  //             $(this).removeClass('hover')
  //         });
  //     });
  //     this.getCurrencys();
  //     this.getCargos();
  // },
  methods: {
    getCurrencys() {
      this.$store.dispatch('getCurrencys').then(() => {
        // this.currencys = localStore.state.currencys;
      });
    },
    getCargos(
      order_field = this.orderField,
      order_desc = this.orderDesc,
      skeep = this.skeep,
      take = this.take
    ) {
      this.cargos = null;

      let tokenId = '';

      if (this.Agent) {
        tokenId = this.Agent.tokenId;
      }

      this.$store.dispatch('getCommonCargos', {
        order_field,
        order_desc,
        skeep,
        take,
        tokenId
      }).then(() => {
        // this.cargos = localStore.state.cargos;
      });
    },
    getStatus(cargoItem) {
      switch (cargoItem.status) {
        case 'lotActive':
          return '<i class="fas fa-hand-holding-usd text-success mr-1"></i>Активная';
        case 'lotRejected':
          return '<i class="fas fa-times-circle text-danger mr-1"></i>Аннулирована';
        case 'lotProcessing':
          return '<i class="fas fa-eye text-secondary mr-1"></i>На рассмотрении';
        case 'lotApproved':
          return '<i class="fas fa-check-square text-success mr-1"></i>Одобрена';
        case 'lotOfferRejected':
          return '<i class="fas fa-ban text-danger mr-1"></i>Отклонена';
        case 'lotClosed':
          return '<i class="fas fa-lock text-danger mr-1"></i>Закрыта';
      }
      return '- - -';
    },
    //     // openMarketplace() {
    //     //     this.$router.push({name: 'Marketplace' });
    //     // },
    openRoute(cargoLotId, routeName, tokenId = '') {
      // this.selectRoute = null;

      if (this.Agent) {
        tokenId = this.Agent.tokenId;
      }

      this.$store.dispatch('getRoute', {
        tokenId,
        cargoLotId
      }).then(() => {
        this.dialogCargo = true;
        this.dialogCargoItem = routeName
        // this.selectRoute = localStore.state.route;
      });

      // $('#routeModal' + cargoLotId).modal('toggle');
    },
    openOffers(cargoLotId, tokenId = '') {
      this.selectOffers = null;

      if (this.Agent) {
        tokenId = this.Agent.tokenId;
      }

      this.$store.dispatch('getOffers', {
        tokenId,
        cargoLotId
      }).then(() => {
        this.dialogOffer = true;
        // this.selectOffers = localStore.state.offers;
      });

      // $('#offersModal' + cargoLotId).modal('toggle');
    },
    isViewTime(pickupDate) {
      return moment(pickupDate).format("HH:mm") != "00:00";
    },
    //     // openLR(nameRoute) {
    //     //     $('#noLoginModal').modal('toggle');
    //     //     this.$router.push({name: nameRoute, query: {redirect: this.$route.path}});
    //     // },
    openAddOfferModal(cargoLotId, rollingStockTypeId, cargoItem) {
      this.storeRollingStockTypeId = rollingStockTypeId;
      this.errorMessageAddOffer = "";
      // this.$refs['addOfferFormRef' + cargoLotId][0].instance.resetValues();
      this.isSendOffer = false;
      this.dialogMakeOffer = true;
      // this.dialogMakeOfferData = cargoItem.cargoLotNumber;
      this.offerNew = {
        cargoItem: cargoItem
      }
      this.$store.dispatch('getRollingStockUnitTypesApi', { storeRollingStockTypeId: rollingStockTypeId })
    },
    AddOffer(cargoLotId) {
      this.isSendOffer = true;
      this.errorMessageAddOffer = "";
      let result = this.$refs['addOfferFormRef' + cargoLotId][0].instance.validate();

      if (result.isValid) {
        const { rollingStockUnitTypeId, amount, currencyId, note } = this.offerNew;

        this.$store.dispatch('sendOffer', { rollingStockUnitTypeId, amount, currencyId, note, cargoLotId }).then(() => {
          this.getCargos();
          this.dialogMakeOffer = false;
          this.errorMessageAddOffer = "";
        }).catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data) {
              let errorMessage = error.response.data;

              this.errorMessageAddOffer = errorMessage;
            } else {
              this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
            }
          } else {
            this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
          }
          this.isSendOffer = false;
          console.error(error);
        }).finally(() => {
          this.dialogMakeOffer = false;
        })
        // .catch(this.handleErrorAddOffer);
      } else {
        this.isSendOffer = false;
      }
    },
    //     // handleErrorAddOffer: function (error) {
    //     //     if (error.response.status === 400) {
    //     //         if(error.response.data) {
    //     //             let errorMessage = error.response.data;

    //     //             this.errorMessageAddOffer = errorMessage;
    //     //         } else {
    //     //             this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
    //     //         }
    //     //     } else {
    //     //         this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
    //     //     }

    //     //     this.isSendOffer = false;
    //     //     console.error(error);
    //     // },
    openEditOfferModal(cargoLotId, rollingStockTypeId, agentOffer, cargoItem) {
      this.storeRollingStockTypeId = rollingStockTypeId;
      this.errorMessageEditOffer = "";
      //let dxsel = this.$refs['editOfferFormRef'+cargoLotId][0].instance.getEditor("rollingStockUnitTypeId");
      // window.rollingStockUnitTypeId = agentOffer.rollingStockUnitTypeId;
      // window.rollingStockUnitTypeName = agentOffer.rollingStockUnitTypeName;
      // this.dialogMakeOfferData = cargoItem.cargoLotNumber;
      this.offerNew = {
        rollingStockUnitTypeId: agentOffer.rollingStockUnitTypeId,
        amount: agentOffer.amount,
        currencyId: agentOffer.currencyId,
        note: agentOffer.note,
        cargoItem: cargoItem,
        type: 1
      };
      this.dialogMakeOffer = true;
      this.isSendOffer = false;
      // $('#editOfferModal' + cargoLotId).modal('toggle');
    },

    EditOffer(cargoLotId, cargoOfferId) {
      this.isSendOffer = true;
      this.errorMessageEditOffer = "";
      let result = this.$refs['editOfferFormRef' + cargoLotId][0].instance.validate();

      if (result.isValid) {
        const { rollingStockUnitTypeId, amount, currencyId, note } = this.offerEdit;

        this.$store.dispatch("sendOffer", {
          cargoOfferId: cargoOfferId,
          rollingStockUnitTypeId: rollingStockUnitTypeId,
          amount: amount,
          currencyId: currencyId,
          note: note
        }).then(() => {
          this.getCargos();
          this.dialogMakeOffer = false;
          // $('#editOfferModal' + cargoLotId).modal('toggle');
          this.errorMessageEditOffer = "";
        })
        // .catch(this.handleErrorEditOffer);
      } else {
        this.isSendOffer = false;
      }
    },
    //     // handleErrorEditOffer: function (error) {
    //     //     if (error.response.status === 400) {
    //     //         if(error.response.data) {
    //     //             let errorMessage = error.response.data;

    //     //             this.errorMessageEditOffer = errorMessage;
    //     //         } else {
    //     //             this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
    //     //         }
    //     //     } else {
    //     //         this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
    //     //     }

    //     //     this.isSendOffer = false;
    //     //     console.error(error);
    //     // },
    DelOffer(cargoLotId, cargoOfferId) {
      this.$store.dispatch('delOffer', { cargoLotId, cargoOfferId }).then(() => {
        this.getCargos();
        this.dialogMakeOffer = false;
        // $('#editOfferModal' + cargoLotId).modal('toggle');
        this.errorMessageEditOffer = "";
      })
      // .catch(this.handleErrorDelOffer);
    },
    //     // handleErrorDelOffer: function (error) {
    //     //     if (error.response.status === 400) {
    //     //         if(error.response.data) {
    //     //             let errorMessage = error.response.data;

    //     //             this.errorMessageEditOffer = errorMessage;
    //     //         } else {
    //     //             this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
    //     //         }
    //     //     } else {
    //     //         this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
    //     //     }

    //     //     this.isSendOffer = false;
    //     //     console.error(error);
    //     // },
  },
  // computed: {
  //     // ...mapGetters([
  //     //     'isLogged',
  //     //     'Agent'
  //     // ])
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.addressItem {
  display: flex;
  align-items: center;
}

.modal-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;

  .modal-title {
    font-size: 18px;
  }

  .closeModalHeader {
    font-size: 28px;
  }
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.companyInNumberMain {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.companyInNumberMainImg {
  width: 100%;
  max-width: 500px;
}

.companyInNumberBg {
  background: #f2f2f2;
}

.companyInNumberTitle {
  font-weight: 400 !important;
}

.companyInNumberBody {
  display: flex;
}

.carouselBtn {
  position: absolute;
  z-index: 10;
}

.carouselBg {
  background: #f2f2f2;
  padding: 32px 0px;
}

.company-in-numbers {
  .title {
    font-size: var(--size-26);
  }

  .img-48 {
    width: 48px;
  }
}
</style>
