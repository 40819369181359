import { createStore } from "vuex";
import common from './common.js';
import cargo from './cargo.js';
import agent from './agent.js';

const store = createStore({
    modules:{
        common,
        cargo,
        agent
    }
})

export default store;