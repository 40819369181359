<template>
    <div class="header fixed-top">
        <div class="container containerTopbar  d-flex">
            <div class="d-flex d-lg-none">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <div class="menu" color="primary" v-bind="props">
                            <i class="fa fa-bars menuIcon" aria-hidden="true"></i>
                        </div>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in routerLinks" :key="index" :value="index + 'smallRouteList'"
                            @click="$router.push({ name: item.to })">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div class="d-flex mr-4 homeImgLogo">
                <img src="@/assets/images/logo.svg" class="logo" @click="openHome" />
            </div>
            <div class="d-flex d-lg-none flex-column justify-content-between ml-auto mobile-right">
                <a href="tel:+77273122110" class="phone mb-4">
                    <img src="@/assets/images/ico-phone.svg" /> +7 (727) 312-21-10
                </a>
                <div v-if="!isCabinetDisabled && !isLogged" class="btn-group btn-group-sm">
                    <v-btn type="button" class="btn btn-secondary clip btnLogin" title="Вход/Регистрация"
                        @click="openLogin">
                        Вход/Регистрация
                    </v-btn>
                </div>
                <div v-else class="btn-group btn-group-sm">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn color="primary" v-bind="props">
                                <i class="fas fa-chevron-down mr-2"></i>{{
                                    Contact
                                        ? Contact.contactName
                                        : 'LogIn'
                                }}
                                <!-- Agent.logIn.substr(0, Agent.logIn.indexOf(":")) -->
                            </v-btn>
                        </template>
                        <v-list>
                            <template v-for="(item, index) in innerLinks" :key="index + 'innerLinks'">
                                <v-list-item @click="$router.push({ name: item.to })" class="vListItem"
                                    v-if="!item.divider" :value="index">
                                    <i v-if="item.to == 'ProfileCounterparty'" class="fas fa-user mr-3"></i>
                                    <i v-if="item.to == 'ProfileChangePassword'" class="fas fa-key mr-3"></i>
                                    <span>{{ item.title }}</span>
                                </v-list-item>
                                <v-divider v-else></v-divider>
                                <!-- <div v-else class="dropdown-divider"></div> -->
                            </template>
                            <v-list-item class="exitBtn" value="exit">
                                <i class="fas fa-sign-out-alt mr-3"></i>
                                <span>Выход</span>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <div class="d-none d-lg-flex flex-column justify-content-between flex-fill ml-4">
                <div class="header-half-top d-flex align-items-start">
                    <nav class="navbar navbar-desktop mr-auto">
                        <ul class="navbar-nav navbarLinks ">
                            <template v-for="(item, index) in routerLinks">
                                <div class="nav-item" @click="$router.push({ name: item.to })"
                                    :key="index + 'routerLinks'" v-if="!item.small">
                                    <div class="nav-link">{{ item.title }}</div>
                                </div>
                            </template>
                        </ul>
                    </nav>
                    <a href="tel:+77273122110" class="phone">
                        <img class="mr-2" src="@/assets/images/ico-phone.svg" /> +7 (727) 312-21-10
                    </a>
                </div>
                <div class="header-half-bottom d-flex">
                    <div class="routesForHash navbar-desktop">
                        <div class="nav-item" v-for="route in routes" :key="route.id">
                            <a class="nav-link routeHash" :href="route.hash">{{ route.title }}</a>
                        </div>
                    </div>
                    <div v-if="!isCabinetDisabled && !isLogged" class="btn-group btn-group-sm">
                        <button type="button" class="btn btn-secondary clip btnLogin" title="Вход/Регистрация"
                            @click="openLogin">
                            Вход/Регистрация
                        </button>
                    </div>
                    <div v-else class="btn-group btn-group-sm dropdown-agent-menu">
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn color="primary" v-bind="props">
                                    <i class="fas fa-chevron-down mr-2"></i>{{
                                        Contact
                                            ? Contact.contactName
                                            : 'LogIn'
                                    }}
                                    <!-- Agent.logIn.substr(0, Agent.logIn.indexOf(":")) -->
                                </v-btn>
                            </template>
                            <v-list>
                                <template v-for="(item, index) in innerLinks" :key="index + 'innerLinks'">
                                    <v-list-item @click="$router.push({ name: item.to })" class="vListItem"
                                        v-if="!item.divider" :value="index">
                                        <i v-if="item.to == 'ProfileCounterparty'" class="fas fa-user mr-3"></i>
                                        <i v-if="item.to == 'ProfileChangePassword'" class="fas fa-key mr-3"></i>
                                        <span>{{ item.title }}</span>
                                    </v-list-item>
                                    <v-divider v-else></v-divider>
                                    <!-- <div v-else class="dropdown-divider"></div> -->
                                </template>
                                <v-list-item class="exitBtn" value="exit">
                                    <i class="fas fa-sign-out-alt mr-3"></i>
                                    <span>Выход</span>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { isCabinetDisabled } from '../../../../environment/environment';
import { mapGetters } from 'vuex';

export default {
    props: ["routes"],
    data() {
        return {
            isCabinetDisabled: false,
            routerLinks: [
                {
                    to: "Home",
                    small: true,
                    title: "Главная",
                },
                {
                    to: "AboutCompany",
                    title: "О компании",
                },
                {
                    to: "Partners",
                    title: "Партнеры",
                },
                {
                    to: "Marketplace",
                    title: "Тендер",
                },
                {
                    to: "Consolidation",
                    title: "Консолидация по РК",
                },
                {
                    to: "CareerCompany",
                    title: "Карьера",
                },
                {
                    to: "Contacts",
                    title: "Контакты",
                },
            ],
            innerLinks: [
                {
                    to: "ProfileCounterparty",
                    title: "Профиль",
                },
                {
                    to: "ProfileChangePassword",
                    title: "Сменить пароль",
                },
                {
                    divider: true
                },
                {
                    to: "CarrierCars",
                    title: "Транспорт",
                },
                {
                    to: "CarrierDrivers",
                    title: "Водители",
                },
                {
                    divider: true
                },
                {
                    to: "CarrierRollingStocks",
                    title: "Перевозки",
                },
                {
                    to: "CarrierOffers",
                    title: "Предложения",
                },
                {
                    divider: true
                },
                {
                    to: "PrimaryDocsContractAppCarriers",
                    title: "ЗПп",
                },
                {
                    divider: true
                },
                {
                    to: "FinancesInvoices",
                    title: "Счета",
                },
                {
                    to: "FinancesPayings",
                    title: "Оплаты",
                },
                {
                    to: "FinancesElementsService",
                    title: "НВУ",
                },
                {
                    divider: true
                }
            ]
        };
    },
    methods: {
        openHome() {
            if (this.$route.path !== "/") {
                this.$router.push("/");
            }
        },
        openLogin() {
            if (this.$route.path !== "/login") {
                this.$router.push({ name: "Login", query: { redirect: this.$route.path } });
            }
        },
        logOut() {
            this.$store.dispatch("logOut", this.Agent);
        },
        isDisplayRouterLink(clsfKind) {
            if (this.clsfCards) {
                let objIndex = this.clsfCards.findIndex((obj) => obj.clsfKind == clsfKind);
                if (objIndex != -1) {
                    return this.clsfCards[objIndex].isActive;
                }
            }

            return false;
        },
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'Agent',
            'Contact',
            // 'clsfCards',
        ])
    },
};
</script>

<style lang="scss" scoped>
.exitBtn {
    color: #e66454 !important;
}

.vListItem {
    display: flex;
    color: #2c5799;
}

.btnLogin {
    padding: 6px 8px;
    border-radius: 6px;
    text-transform: none;
}

.navbarLinks {
    display: flex;
    align-items: center;

    .nav-link {
        padding: 8px 6px;
    }
}

.routeHash {
    text-decoration: none;
}

.menu {
    background: #2c5799;
    width: 74px;
    border-radius: 0px !important;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    .menuIcon {
        font-size: 40px;
    }
}

.homeImgLogo {
    @media (max-width: 768px) {
        padding: 16px;
    }
}

.containerTopbar {
    max-height: 100px;

    @media (max-width: 768px) {
        padding: 0px !important;
    }
}

.header {
    padding: 1rem 0;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;

    @media (max-width: 768px) {
        padding: 0px !important;
    }

    .logo {
        width: 80px;

        @media (min-width: 576px) {
            width: auto;
        }
    }

    .phone {
        color: #000;
        display: flex;
        align-items: center;
        text-decoration: none;

    }

    .header-title {
        padding-bottom: 0;
        font-size: var(--size-22);
        color: #000;
    }
}

.navbar-desktop {
    padding: 0;

    .nav-link {
        padding-top: 0;
        color: #777;
        position: relative;
        transition: var(--default-transition);
    }

    .nav-link:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 4px;
        border-radius: 2px 2px 0px 0px;
    }
}

.header-half-top {
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #d8d8d8;

    .navbar-desktop {
        .nav-item+.nav-item {
            margin-left: 2rem;
        }

        .nav-link:hover:after,
        .nav-link.active:after {
            background: var(--color-primary);
        }

        .nav-link:hover {
            color: var(--color-primary);
        }
    }
}

.mobile-right {
    padding: 16px;

    .dropdown-toggle::after {
        display: none;
    }
}

.header-half-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .routesForHash {
        display: flex;
        align-items: center;

        .nav-link {
            padding: 0px 8px;
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    .nav-link {
        padding-bottom: 0;
        font-size: var(--size-22);
        color: #000;
    }

    h2 {
        font-size: var(--size-22);
        line-height: 1.4;
    }

    .nav-item:hover {
        .nav-link:after {
            background: var(--color-primary);
        }
    }

    /*.dropdown-agent-menu:hover>.dropdown-menu {*/
    /*    display: block;*/
    /*}*/

    /*.nav-item:nth-child(1) {*/
    /*  .nav-link:after {*/
    /*    background: var(--color-primary);*/
    /*  }*/
    /*}*/

    /*.nav-item:nth-child(2) {*/
    /*  .nav-link:after {*/
    /*    background: var(--color-yellow);*/
    /*  }*/
    /*}*/

    /*.nav-item:nth-child(3) {*/
    /*  .nav-link:after {*/
    /*    background: var(--color-secondary);*/
    /*  }*/
    /*}*/

    .nav-link:after {
        bottom: -16px;
    }

    .nav-item+.nav-item {
        margin-left: 3rem;
    }
}

.navbar-toggler:active,
.navbar-toggler:focus {
    outline: none;
    border: 0;
}

.navbar-toggler {
    padding: 0.25rem 1rem;
    margin: -1rem 1rem -1rem -15px;
    border-radius: 0;
    background-color: var(--color-primary);
    outline: none;
    border: 0;
}

@media (min-width: 576px) {
    .navbar-toggler {
        padding: 0.25rem 1.25rem;
    }
}

.navbar-toggler-icon {
    width: 34px;
    height: 3px;
    position: relative;
    background-color: #fff;
}

.navbar-toggler-icon:before {
    top: 12px;
}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    content: "";
    position: absolute;
    left: 0;
    width: 34px;
    height: 3px;
    background-color: #fff;
}

.navbar-toggler-icon:after {
    bottom: 12px;
}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    content: "";
    position: absolute;
    left: 0;
    width: 34px;
    height: 3px;
    background-color: #fff;
}

.mobile-navbar {
    padding-top: 30px;
}

.mobile-navbar .nav-item {
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
}

.mobile-navbar .nav-item.global,
.mobile-navbar .nav-item.delivery,
.mobile-navbar .nav-item.moving {
    border-bottom: 0;
}

.mobile-navbar .nav-link {
    padding: 0.75rem 15px;
    font-size: 18px;
    color: #777;
}

.mobile-navbar .nav-item.global>.nav-link {
    margin: 0 -15px;
    /*border-bottom: 5px solid var(--color-primary);*/
}

.mobile-navbar .nav-item.global>.nav-link,
.mobile-navbar .nav-item.delivery>.nav-link,
.mobile-navbar .nav-item.moving>.nav-link {
    font-size: 22px;
    color: #000;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon:before {
    transform: rotate(45deg) translate(-9px, -9px);
    transform-origin: center;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
    transform: rotate(-45deg) translate(-8px, 8px);
    transform-origin: center;
}
</style>
