import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import './style/main.scss';
import Notifications from '@kyvg/vue3-notification';
const vuetify = createVuetify({
  components,
  directives,
});
createApp(App).use(store).use(Notifications).use(router).use().use(vuetify).mount("#app");
