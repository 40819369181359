import moment from "moment";
import axiosBackend from "../backendConnection";

const cargoStore = {
  state: {
    routeClassifier: null,
    groupsCode: null,
    currencys: null,
    cargos: null,
    route: null,
    offers: null,
    searchLocalitys: [],
    rollingStock: [],
    rollingStockUnitTypes: [],
    // searchFrom: null,
    // searchTo: null,
  },
  getters: {
    getRollingStock(state) {
      return state.rollingStock;
    },
    getSearchLocalitys(state) {
      return state.searchLocalitys;
    },
    getRouteClassifier(state) {
      return state.routeClassifier;
    },
    getGroupsCode(state) {
      return state.groupsCode;
    },
    getCargos(state) {
      return state.cargos;
    },
    getOffers(state) {
      return state.offers;
    },
    getRoute(state) {
      return state.route;
    },
    getCurrencys(state) {
      return state.currencys;
    },
    getRollingStockUnitTypes(state) {
      return state.rollingStockUnitTypes;
    },
  },
  mutations: {
    setRollingStockData(state, data) {
      state.rollingStock = data;
    },
    setSearchData(state, data) {
      state.searchLocalitys = data;
    },
    setRouteClassifier(state, data) {
      state.routeClassifier = data;
    },
    setGroupsCode(state, data) {
      state.groupsCode = data;
    },
    setCurrencys(state, data) {
      state.currencys = data;
    },
    setCargos(state, data) {
      state.cargos = data;
    },
    setRoute(state, data) {
      state.route = data;
    },
    setOffers(state, data) {
      state.offers = data;
    },
    setRollingStockUnitTypes(state, data) {
      state.rollingStockUnitTypes = data;
    },
  },
  actions: {
    getRollingStockUnitTypesApi({ commit }, { storeRollingStockTypeId }) {
      let searchValue = "";
      // loadOptions.searchValue != null ? loadOptions.searchValue : "";

      let filter = "";
      searchValue != ""
        ? `,{"rollingStockUnitTypeName":{"op":"con","value":"${searchValue}"}}`
        : "";

      return axiosBackend
        .get(
          `Catalog/RollingStockUnitTypes?filter={"rollingStockTypeId":{"op":"eq","value":"${storeRollingStockTypeId}"}}${filter}`
        )
        .then((data) => {
          commit("setRollingStockUnitTypes", data.data.items);
          // return {
          //   key: "rollingStockUnitTypeId",
          //   data: data.data.items,
          //   totalCount: data.totals,
          //   summary: data.totals,
          //   groupCount: data.count,
          // };
        });
      // .catch(() => {
      //   throw "Data Loading Error";
      // });
    },
    getRollingStockApi({ commit }, { searchValue = "" }) {
      let filter =
        searchValue != ""
          ? `?filter={"rollingStockTypeName":{"op":"con","value":"${searchValue}"}}`
          : "";
      return axiosBackend
        .get(`Catalog/RollingStockTypes${filter}`)
        .then((data) => {
          commit("setRollingStockData", data.data);
        });
    },
    getSearchDataApi({ commit }, { searchValue = null }) {
      return axiosBackend
        .get(
          `Catalog/Localitys?filter={"localityName":{"op":"con","value":"${searchValue}"}}`
        )
        .then((data) => {
          commit("setSearchData", data.data);
        });
    },
    getRouteClassifier({ commit }) {
      commit("setRouteClassifier", null);
      return axiosBackend
        .get("Marketplace/Cargos/route-classifier")
        .then((response) => {
          commit("setRouteClassifier", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getGroupsCode({ commit }, { tokenId, routeClassifierId }) {
      commit("setGroupsCode", null);

      let url = `Marketplace/Cargos/route-classifier/${routeClassifierId}/groups`;

      if (tokenId) {
        url = url + `?tokenId=${tokenId}`;
      }

      return axiosBackend
        .get(url)
        .then((response) => {
          commit("setGroupsCode", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getCurrencys({ commit }) {
      commit("setCurrencys", null);
      return axiosBackend
        .get("Catalog/Currencys")
        .then((response) => {
          commit("setCurrencys", response.data.items);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getCargos(
      { commit },
      {
        tokenId,
        routeClassifierId,
        order_field,
        order_desc,
        skeep,
        take,
        advancedSearch,
        groupCode,
        cargoLotId,
      }
    ) {
      commit("setCargos", null);
      let url = `Marketplace/Cargos?order_field=${order_field}&order_desc=${order_desc}&skeep=${skeep}&take=${take}`;

      if (tokenId) {
        url = url + `&tokenId=${tokenId}`;
      }

      if (!cargoLotId && routeClassifierId) {
        url = url + `&routeClassifierId=${routeClassifierId}`;
      }

      if (!cargoLotId && groupCode) {
        url = url + `&groupCode=${groupCode}`;
      }

      let filter = "";
      let separator = "";

      if (advancedSearch.acn) {
        filter += `${separator}{"acn":{"op":"eq","value":"${advancedSearch.acn}"}}`;
        separator = ", ";
      }

      if (advancedSearch.fromLocalityId) {
        filter += `${separator}{"firstLocality.localityId":{"op":"eq","value":"${advancedSearch.fromLocalityId}"}}`;
        separator = ", ";
      }

      if (advancedSearch.toLocalityId) {
        filter += `${separator}{"lastLocality.localityId":{"op":"eq","value":"${advancedSearch.toLocalityId}"}}`;
        separator = ", ";
      }

      if (advancedSearch.rollingStockTypeId) {
        filter += `${separator}{"rollingStockTypeId":{"op":"eq","value":"${advancedSearch.rollingStockTypeId}"}}`;
        separator = ", ";
      }

      if (advancedSearch.weightMin) {
        filter += `${separator}{"cargo.weight":{"op":"gte","value":${
          advancedSearch.weightMin * 1000
        }}}`;
        separator = ", ";
      }

      if (advancedSearch.weightMax) {
        filter += `${separator}{"cargo.weight":{"op":"lte","value":${
          advancedSearch.weightMax * 1000
        }}}`;
        separator = ", ";
      }

      if (advancedSearch.volumeMin) {
        filter += `${separator}{"cargo.volume":{"op":"gte","value":${advancedSearch.volumeMin}}}`;
        separator = ", ";
      }

      if (advancedSearch.volumeMax) {
        filter += `${separator}{"cargo.volume":{"op":"lte","value":${advancedSearch.volumeMax}}}`;
        separator = ", ";
      }

      if (advancedSearch.loadingType) {
        filter += `${separator}{"cargo.loadingType":{"op":"eq","value":"${advancedSearch.loadingType}"}}`;
        separator = ", ";
      }

      if (advancedSearch.pickupDate) {
        let dateEnd = moment(advancedSearch.pickupDate).add(1, "days");
        filter += `${separator}{"and":[{"pickupDate":{"op":"gte","value":"${moment(
          advancedSearch.pickupDate
        ).format(
          "YYYY-MM-DDT00:00:00"
        )}"}}, {"pickupDate":{"op":"lt","value":"${dateEnd.format(
          "YYYY-MM-DDT00:00:00"
        )}"}}]}`;
        separator = ", ";
      }

      if (cargoLotId) {
        filter += `${separator}{"cargoLotId":{"op":"eq","value":"${cargoLotId}"}}`;
        separator = ", ";
      }

      if (filter != "") {
        url = url + `&filter={"and":[${filter}]}`;
      }

      return axiosBackend.get(url).then((response) => {
        commit("setCargos", response.data);
      });
    },
    getRoute({ commit }, { tokenId, cargoLotId }) {
      commit("setRoute", null);
      let url = `Marketplace/Cargos/${cargoLotId}/Route`;

      if (tokenId) {
        url = url + `?tokenId=${tokenId}`;
      }

      return axiosBackend
        .get(url)
        .then((response) => {
          commit("setRoute", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getOffers({ commit }, { tokenId, cargoLotId }) {
      commit("setOffers", null);
      let url = `Marketplace/Cargos/${cargoLotId}/Offers`;

      if (tokenId) {
        url = url + `?tokenId=${tokenId}`;
      }

      return axiosBackend
        .get(url)
        .then((response) => {
          commit("setOffers", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    sendOffer(
      context,
      {
        cargoOfferId = null,
        rollingStockUnitTypeId,
        amount,
        currencyId,
        note,
        cargoLotId,
      }
    ) {
      let data = {
        rollingStockUnitTypeId: rollingStockUnitTypeId,
        amount: amount,
        currencyId: currencyId,
        note: note,
      };
      if (cargoOfferId) data.cargoOfferId = cargoOfferId;
      return axiosBackend.post(
        `Marketplace/Cargos/${cargoLotId}/Offers?tokenId=${this.Agent.tokenId}`,
        data
      );
    },
    delOffer({cargoLotId,cargoOfferId}) {
      return axiosBackend.delete(
        `Marketplace/Cargos/${cargoLotId}/Offers/${cargoOfferId}?tokenId=${this.Agent.tokenId}`
      );
    },
  },
};

export default cargoStore;
