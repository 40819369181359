<template>
    <div class="container pa-4">
        <div class="text-center mb-3">
            <h1 class="mb-4 page-title">РАСЧЕТ СТОИМОСТИ ПЕРЕВОЗКИ</h1>
        </div>
        <div class="consolidationBody">
            <div class="w-100 mr-4">
                <div class="fields">
                    <span class="fieldsSpan">Откуда:</span>
                    <v-select :disable="true" placeholder="Выберите откуда" item-title="name" item-value="index"
                        :items="zones" v-model="from"></v-select>
                </div>
                <div class="fields">
                    <span class="fieldsSpan">Куда:</span>
                    <v-select return-object placeholder="Выберите куда" item-title="name" item-value="index"
                        :items="zones" v-model="to"></v-select>
                </div>
                <div class="fields">
                    <span class="fieldsSpan">Вес (кг):</span>
                    <v-text-field suffix="кг" type="number" placeholder="Введите вес" v-model="weight"></v-text-field>
                </div>


            </div>
            <div class="w-100 unitsSide">
                <!-- <span>Выберите единицу измерения (метры, сантиметры, миллиметры) и введите известные вам значения в
                    соответствующие поля</span> -->
                <div>
                    <div class="fields">
                        <span class="fieldsSpan">Eдиница измерения:</span>
                        <v-select item-title="name" return-object item-value="value"
                            placeholder="Выберите единицу измерения" :items="unitMeasurement"
                            v-model="selectedUnit"></v-select>
                    </div>
                    <div class="sizes">
                        <div class="mr-2 fields">
                            <span class="fieldsSpan">Длина:</span>
                            <v-text-field type="number" placeholder="Введите длину"
                                v-model="unitsFields.length"></v-text-field>
                        </div>
                        <div class="mr-2 fields">
                            <span class="fieldsSpan">Ширина:</span>
                            <v-text-field type="number" placeholder="Введите ширину"
                                v-model="unitsFields.width"></v-text-field>
                        </div>
                        <div class="fields">
                            <span class="fieldsSpan">Высота:</span>
                            <v-text-field type="number" placeholder="Введите высоту"
                                v-model="unitsFields.height"></v-text-field>
                        </div>
                    </div>
                    <div class="resultFields" v-if="result && to.zone">
                        <span>Стоимость перевозки: {{ result }} тг</span>
                        <span>Сроки доставки (рабочие дни): {{ byZone[to.zone - 1]?.time }}</span>
                        <span>Объем (м3): {{ resultUnit }}</span>
                    </div>
                    <!-- <div v-if="resultUnit && selectedUnit">
                        <span>Результат:</span>
                        <span>{{ resultUnit }} м3 </span>
                    </div>
                    <div class="calculateBtn">
                        <v-btn color="#e66454" class="clear">
                            Очистить
                        </v-btn>
                        <v-btn @click="calculateUnit()" color="#5ebd5e" class="calculate">
                            Рассчитать
                        </v-btn>
                    </div> -->
                </div>
            </div>
        </div>
        <div>
            <div class="calculateBtn">
                <v-btn @click="clear()" color="#e66454" class="clear">
                    Очистить
                </v-btn>
                <v-btn @click="calculate()" color="#5ebd5e" class="calculate">
                    Рассчитать
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            resultUnit: 0,
            from: {
                name: 'Алматы',
                zone: 1
            },
            to: null,
            weight: null,
            result: 0,
            selectedUnit: null,
            unitsFields: {
                length: null,
                width: null,
                height: null
            },
            unitMeasurement: [
                {
                    value: 1,
                    name: "метры"
                },
                {
                    value: 0.000001,
                    name: "сантиметры"
                },
                {
                    value: 0.000000001,
                    name: "миллиметры"
                },
                // {
                //     value: 0.001,
                //     name: "Дециметры"
                // }
            ],
            byZone: [
                {
                    minFee: 14000,
                    grade: [
                        90, 85, 80, 70
                    ],
                    time: "2-3"
                },
                {
                    minFee: 17000,
                    grade: [
                        115, 110, 100, 85
                    ],
                    time: "3-4"
                },
                {
                    minFee: 21000,
                    grade: [
                        130, 125, 115, 95
                    ],
                    time: "4-6"
                },
                {
                    minFee: 24000,
                    grade: [
                        145, 140, 125, 105
                    ],
                    time: "5-7"
                }
            ],
            zones: [
                {
                    name: "Алматы",
                    zone: 1
                },
                {
                    name: "Астана",
                    zone: 1
                },
                {
                    name: "Тараз",
                    zone: 1
                },
                {
                    name: "Шымкент",
                    zone: 1
                },
                {
                    name: "Талдыкорган",
                    zone: 1
                },
                {
                    name: "Караганда",
                    zone: 1
                },



                {
                    name: "Кызылорда",
                    zone: 2
                },
                {
                    name: "Балхаш",
                    zone: 2
                },
                {
                    name: "Кокшетау",
                    zone: 2
                },
                {
                    name: "Костанай ",
                    zone: 2
                },
                {
                    name: "Павлодар",
                    zone: 2
                },
                {
                    name: "Петропавловск",
                    zone: 2
                },



                {
                    name: "Семей",
                    zone: 3
                },
                {
                    name: "Усть-Каменогорск",
                    zone: 3
                },
                {
                    name: "Актобе",
                    zone: 3
                },
                {
                    name: "Уральск ",
                    zone: 3
                },
                {
                    name: "Атырау",
                    zone: 3
                },
                {
                    name: "Актау",
                    zone: 3
                },


                {
                    name: "Экибастуз",
                    zone: 4
                },
                {
                    name: "Жезказган",
                    zone: 4
                },
                {
                    name: "Жанаозен",
                    zone: 4
                },
                {
                    name: "Туркестан ",
                    zone: 4
                },
                {
                    name: "Темиртау",
                    zone: 4
                }
            ],
            fuelSurcharge: 10
        }
    },
    methods: {
        clear() {
            this.to = null;
            this.weight = null;
            this.result = 0;
            this.selectedUnit = null;
            this.unitsFields = {
                length: null,
                width: null,
                height: null
            };
        },
        // (ВЕС после 100кг × ТАРИФ) + МИНИМАЛЬНЫЙ СБОР +10% топливная надбавка 
        calculate() {
            if (!this.to || !this.to?.zone || !this.weight) {
                // alert("Заполните все поля")
                this.$notify({
                    title: "Заполните все поля",
                    type: 'error'
                });
                return
            }
            if (!this.selectedUnit || !this.selectedUnit.value) {
                this.$notify({
                    title: "Выберите единицу измерения",
                    type: 'error'
                });
                return
            }
            if (!this.unitsFields.height || !this.unitsFields.length || !this.unitsFields.width) {
                this.$notify({
                    title: "Заполните все поля",
                    type: 'error'
                });
                return
            }

            let zone = this.to?.zone;
            if (this.unitsFields.height && this.unitsFields.length && this.unitsFields.width) {
                this.resultUnit = Number((Number(this.unitsFields.height) * Number(this.unitsFields.length) * Number(this.unitsFields.width)) * this.selectedUnit.value).toFixed(2);
            }
            this.result = 0;
            let weightMin = 0;
            // if (this.weight < 200) {
            //     weightMin = (Number(this.resultUnit) * 200) - 100;
            // } else {
                weightMin = Number(this.weight) - 100
            // }
            let gradeTemp = 0;
            if (this.weight > 100) {
                switch (true) {
                    case (weightMin >= 100 && weightMin < 300):
                        gradeTemp = 0;
                        break;
                    case (weightMin > 301 && weightMin < 1000):
                        gradeTemp = 1;
                        break;
                    case (weightMin > 1001 && weightMin < 3000):
                        gradeTemp = 2;
                        break;
                    case (weightMin > 3001):
                        gradeTemp = 3;
                        break;
                }
            }
            this.result = (Number(this.byZone[zone - 1].grade[gradeTemp]) * Number(weightMin)) + Number(this.byZone[zone - 1].minFee);
            this.result = Math.round(((this.result * this.fuelSurcharge) / 100) + this.result);

        },

        calculateUnit() {

            this.resultUnit = Math.round((Number(this.unitsFields.height) * Number(this.unitsFields.length) * Number(this.unitsFields.width)) * this.selectedUnit.value)
        }
    }

}
</script>

<style lang="scss" scoped>
.resultFields {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

}

.fields {
    display: flex;
    flex-direction: column;
}

.fieldsSpan {
    font-weight: 700;
    margin-bottom: 8px;
}

.sizes {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.calculateBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.clear {
    margin-right: 16px;
}

.calculate {}

.consolidationBody {
    display: flex;

    @media screen and (max-width:768px) {
        flex-direction: column;

        .unitsSide {
            margin-top: 16px;
        }
    }
}
</style>