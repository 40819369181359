<template>
    <div>
        <div id="career" class="container py-4">
            <div class="text-center mb-3">
                <h1 class="mb-4 page-title">Карьера</h1>
            </div>
            <div class="careerInfo">
                <div>
                    <div class="bg-white h-100">
                        <div class="pt-4 mx-4 pb-5">
                            <strong class="text-primary">КЕЛ</strong> – это надежная работа и уверенность в будущем.
                            Вместе мы смотрим в сторону общего успеха.<br>
                            Если ты талантлив, стремишься к развитию и росту, стань частью нашей команды.
                        </div>
                        <div class="d-flex pt-5 pt-lg-5 mb-2 ml-4">
                            <img src="@/assets/images/contacts/user.png" class="align-self-center mr-3"
                                alt="РК, г. Алматы, ул. Майлина 85/2" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-1 mb-1">Галина Божко<br><small class="text-muted">Директор по
                                        персоналу</small></h3>
                            </div>
                        </div>
                        <div class="d-flex pt-3 mb-2 ml-4">
                            <img src="@/assets/images/contacts/phone.png" class="align-self-center mr-3"
                                alt="+7 (727) 3-122-110" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a class="mediaLink" href="tel:+77717016336" style="color: #212529;">+7
                                        771 701 63 36</a></h3>
                            </div>
                        </div>
                        <div class="d-flex pt-3 pb-3 ml-4">
                            <img src="@/assets/images/contacts/email.png" class="align-self-center mr-3"
                                alt="info@kel.kz" style="width: 55px">
                            <div class="media-body">
                                <h3 class="mt-2"><a class="mediaLink" href="mailto:galina.bozhko@kel.kz"
                                        style="color: #212529;">galina.bozhko@kel.kz</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="bg-white h-100 d-flex align-items-center">
                        <img src="@/assets/images/career-company/1.jpeg" class="w-100">
                    </div>
                </div>
            </div>
            <div id="vacancies" class="text-center mb-3">
                <h1 class="mb-4 page-title">Вакансии</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="getAllJobs && getAllJobs.length > 0">
                        <div class="cardCareerCompany " v-for="(job, index) in getAllJobs" :key="index">
                            <div v-if="!job.show" class="card-header  font-26 border-bottom-0">
                                <div class="row">
                                    <div class="col-12 col-md-6 text-secondary">
                                        {{ job.position }}
                                    </div>
                                    <div class="col-12 col-md-6 text-left text-md-right">
                                        <span class="card-text text-muted font-18">{{ job.rate }}</span>
                                    </div>
                                </div>
                                <p class="card-text font-16 mt-2">
                                    <span class="mr-1" v-for="(responsibility, index) in job.responsibilities"
                                        :key="index">{{ responsibility }};</span>
                                </p>
                            </div>
                            <div v-else class="card-body ">
                                <h3 class="card-title mb-2 mt-2">Обязаности</h3>
                                <p class="card-text mb-0" v-for="(responsibility, index) in job.responsibilities"
                                    :key="'res-' + index" v-html="'- ' + responsibility"></p>
                                <h3 class="card-title mb-2 mt-2">Требования</h3>
                                <p class="card-text mb-0" v-for="(requirement, index) in job.requirements"
                                    :key="'req-' + index" v-html="'- ' + requirement"></p>
                                <h3 class="card-title mb-2 mt-2">Условия</h3>
                                <p class="card-text mb-0" v-for="(condition, index) in job.conditions"
                                    :key="'c-' + index" v-html="'- ' + condition"></p>
                                <hr>
                                <h3 class="card-title mb-2 mt-2">Галина Божко<br><small class="text-muted">Директор по
                                        персоналу</small></h3>
                                <p class="card-text mb-0">
                                    <span>Тел: <a href="tel:+77717016336">+7 771 701 63 36</a> </span>
                                </p>
                                <p class="card-text mb-0">
                                    <span>Email: <a href="mailto:galina.bozhko@kel.kz">galina.bozhko@kel.kz</a></span>
                                </p>
                            </div>
                            <div class="card-footer cardCareerCompanyBottom bg-white">
                                <div class="col-6">
                                    <button :id="'card-' + index + '-btn'" type="button" class="btn cardCareerCompanyBottomBtn btn-primary btn-sm"
                                        @click="job.show = !job.show">
                                            <span v-if="!job.show">Подробно</span>
                                            <span v-else>Скрыть</span>
                                        </button>
                                </div>
                                <div class="col-6 pt-1 font-14 text-right text-muted">
                                    <i class="far fa-calendar-alt mr-2"></i> {{ job.publisher_date }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h5 class="my-5">На данный момент открытых вакансий нет.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';



export default {
    computed: {
        ...mapGetters(['getAllJobs'])
    },
    mounted() {
        this.$store.dispatch('getJobs')
    }

}
</script>

<style lang="scss" scoped>
.cardCareerCompanyBottom {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    margin-top: 16px;
    .cardCareerCompanyBottomBtn{
        padding: 4px 16px;
        border-radius: 4px;
    }
}

.cardCareerCompany {
    padding: 24px;
    margin-bottom: 24px;
    background: white;
}

.mediaLink {

    text-decoration: none;

}

.careerInfo {
    display: flex;

    @media screen and (max-width:768px) {
        flex-direction: column;
    }
}
</style>
