
import axiosBackend from "../backendConnection";


const cargoStore = {
    state: {
        loading: false,
        agent: null,
        contact: null,
        clsfCards: null
    },
    mutations: {
        setLoading(state, data = false) {
            state.loading = data;
        },
        setAgent(state, data) {
            state.agent = data;
            localStorage.setItem('agent', JSON.stringify(data));
        },
        setContact(state, data) {
            state.contact = data;
            localStorage.setItem('contact', JSON.stringify(data));
        },
        clearAgent(state) {
            state.agent = null;
            state.clsfCards = null;
            state.contact = null;
            localStorage.removeItem('agent');
            localStorage.removeItem('clsfCards');
            localStorage.removeItem('contact');
            location.reload();
        },
        setClsfCards(state, data) {
            state.clsfCards = data;
            localStorage.setItem('clsfCards', JSON.stringify(data));
        },
        clearClsfCards(state) {
            state.clsfCards = null;
            localStorage.removeItem('clsfCards');
        },
        clearContact(state) {
            state.contact = null;
            localStorage.removeItem('contact');
        },
    },
    actions: {
        loading({commit}, data) {
            commit('setLoading', data);
        },
        logIn({commit}, data) {
            commit('setAgent', data);

            axiosBackend
                .get(`Contacts/${data.contactId}?tokenId=${data.tokenId}`)
                .then((response) => { // response
                    commit('setContact', response.data);
                }).catch(function (error) {
                //commit('clearAgent');
                console.error(error);
            });

            if(data.isVerified) {
                axiosBackend
                    .get(`ClsfCards?tokenId=${data.tokenId}`)
                    .then((response) => { // response
                        commit('setClsfCards', response.data);
                    }).catch(function (error) {
                    //commit('clearAgent');
                    console.error(error);
                });
            }
        },
        logOut({commit}, agent) {
            return axiosBackend
                .post(`Agent/${agent.tokenId}/Logout`)
                .then(() => { // response
                    commit('clearAgent');
                }).catch(function (error) {
                    commit('clearAgent');
                    console.error(error);
                });
        },
        updateAgent({commit}, data) {
            commit('setAgent', data);
        },
    },
    getters: {
        isLogged: state => !!state.agent,
        isLoading: state => state.loading,
        Agent: state => state.agent,
        Contact: state => state.contact,
        clsfCards: state => state.clsfCards
    }
};

export default cargoStore;
