import axiosBackend from "../backendConnection";
import axios from "axios";
import moment from "moment";

const commonStore = {
  state() {
    return {
      jobs: [],
      commonCargos: null,
      commonRoute: null,
    };
  },
  getters: {
    getAllJobs(state) {
      return state.jobs;
    },
    getCommonCargos(state) {
      return state.cargos;
    },
    getCommonRoute(state) {
      return state.route;
    },
  },
  mutations: {
    setJobs(state, data) {
      state.jobs = data;
    },
    setCommonCargos(state, data) {
      state.cargos = data;
    },
    setCommonRoute(state, data) {
      state.route = data;
    },
  },
  actions: {
    getJobs(context) {
      axios
        .get("/data/jobs.json?" + Math.round(+new Date() / 1000))
        .then((response) => {
          context.commit("setJobs", response.data);
        });
    },
    getCommonCargos(context, { order_field, order_desc, skeep, take, tokenId }) {
      let url = `Marketplace/Cargos?order_field=${order_field}&order_desc=${order_desc}&skeep=${skeep}&take=${take}&groupCode=active`;

      if (tokenId) {
        url = url + `&tokenId=${tokenId}`;
      }

      url =
        url +
        `&filter={"and":[{"pickupDate":{"op":"gte","value":"${moment(
          moment().toDate()
        ).format("YYYY-MM-DDT00:00:00")}"}}]}`;

      return axiosBackend
        .get(url)
        .then((response) => {
          context.commit("setCommonCargos", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getCommonCurrencys(context) {
      return axiosBackend
        .get("Catalog/Currencys")
        .then((response) => {
          context.commit("setCommonCurrencys", response.data.items);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getCommonRoute(context, { tokenId, cargoLotId }) {
      let url = `Marketplace/Cargos/${cargoLotId}/Route`;

      if (tokenId) {
        url = url + `?tokenId=${tokenId}`;
      }

      return axiosBackend
        .get(url)
        .then((response) => {
          context.commit("setCommonRoute", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    getCommonOffers(context, { tokenId, cargoLotId }) {
      let url = `Marketplace/Cargos/${cargoLotId}/Offers`;

      if (tokenId) {
        url = url + `?tokenId=${tokenId}`;
      }

      return axiosBackend
        .get(url)
        .then((response) => {
          context.commit("setCommonOffers", response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};

export default commonStore;
