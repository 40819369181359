import axios from "axios";

const mainAxios = axios.create({
  baseURL: 'https://app.kel.kz/api/v2/'
});
mainAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if(process.env.VUE_APP_API_KEY){
    config.headers.ApiKey = process.env.VUE_APP_API_KEY;
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

mainAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Redirect to login page
        this.$router.push("/login");
      } else {
        // Show a generic error message
        alert("Ошибка кроме 401");
      }
    }
    return Promise.reject(error);
  }
);

export default mainAxios;