<template>
    <div>
        <div id="about-company" class="container pt-4">
            <div class="text-center mb-3">
                <h1 class="mb-4 page-title">О компании</h1>
            </div>
            <div class="aboutCompany">
                <div class="aboutCompanyInfo">
                    <div class="bg-white h-100 p-4">
                        <p class="mb-10"><strong class="text-primary">КЕЛ</strong> - cервисно-логистическая компания,
                            призванная объединить интересы участников рынка автомобильных перевозок РК -
                            грузоотправителей, грузоперевозчиков, грузополучателей и сервисных компаний</p>
                        <h4 class="text-primary text-uppercase mt-4">Наша миссия</h4>
                        <p class="mb-10">Увеличение транзитного потенциала и развитие отрасли автомобильных перевозок
                            Республики Казахстан</p>
                        <h4 class="text-primary text-uppercase mt-4">Дата основания компании</h4>
                        <p>январь 2016 года</p>
                    </div>
                </div>
                <div>
                    <div class="bg-white h-100 d-flex align-items-center">
                        <img src="@/assets/images/about-company/1.jpeg" class="w-100">
                    </div>
                </div>
            </div>
        </div>
        <div id="company-in-numbers" class="company-in-numbers">
            <div class="pt-5 pb-3">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="title">Компания в цифрах</div>
                    </div>
                </div>
            </div>
            <div class="bg-white py-4">
                <div class="container">
                    <div class="d-flex justify-space-between">
                        <div>
                            <div v-for="(item, index) in companyInNumber" :key="index + 'companyInNumber'"
                                class="companyInNumberBody mb-3">
                                <img src="@/assets/images/company-in-numbers/confirmed.png"
                                    class="align-self-start mr-3 mt-3 img-48"
                                    alt="8 лет на рынке международных грузовых автомобильных перевозок" />
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-34 mb-1 companyInNumberTitle">
                                        {{ item.title }}
                                    </h3>
                                    <p class="text-primary font-18">
                                        {{ item.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/images/company-in-numbers/volume-sales.png"
                                class="img-fluid mx-auto d-block mt-3"
                                alt="Объем продаж за 2020 год составил 2,3 млрд тенге" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="services" class="services">
            <div class="pt-5 pb-3">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="title">Услуги</div>
                    </div>
                </div>
            </div>
            <div class="bg-white">
                <div class="container">
                    <div class="d-flex justify-space-between">
                        <div class="service">
                            <div class="d-flex align-center" v-for="(item, index) in service" :key="index + 'service'">
                                <img src="@/assets/images/about-company/confirmed.png"
                                    class="align-self-start mr-3 img-48 " :alt="item">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-32 mb-1 serviceBody">{{ item }}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="serviceImg">
                            <div class="bg-white h-100 d-flex align-items-center">
                                <img src="@/assets/images/about-company/services.jpeg" class="w-100" alt="Услуги">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="our-advantages" class="our-advantages">
            <div class="pt-5 pb-3">
                <div class="container">
                    <div class="advantages">
                        <div class="title">Наши преимущества</div>
                        <div class="total d-block"><span class="text-primary font-22">8 000</span> ЕДИНИЦ АВТОТРАНСПОРТА
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white pt-4 pb-4">
                <div class="container">
                    <div class="advantagesList">
                        <div class="card border-0 mb-6">
                            <img src="@/assets/images/about-company/our-advantages-1.png" class="card-img-top advantagesListImg"
                                alt="ПРЯМЫЕ АВТО">
                            <div class="card-body">
                                <div class="d-flex mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png"
                                        class="align-self-start mr-3 img-48 advantagesList" alt="ПРЯМЫЕ АВТО">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28 serviceBody">ПРЯМЫЕ<br>АВТО</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0 mb-6">
                            <img src="@/assets/images/about-company/our-advantages-2.png" class="card-img-top advantagesListImg"
                                alt="ПРОВЕРЕННЫЕ АВТО">
                            <div class="card-body">
                                <div class="d-flex mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png"
                                        class="align-self-start mr-3 img-48" alt="ПРОВЕРЕННЫЕ АВТО">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28 serviceBody">ПРОВЕРЕННЫЕ<br>АВТО</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0 mb-6">
                            <img src="@/assets/images/about-company/our-advantages-3.png" class="card-img-top advantagesListImg"
                                alt="СЛЕЖЕНИЕ ГРУЗОВ">
                            <div class="card-body">
                                <div class="d-flex mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png"
                                        class="align-self-start mr-3 img-48" alt="СЛЕЖЕНИЕ ГРУЗОВ">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28 serviceBody">СЛЕЖЕНИЕ<br>ГРУЗОВ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 mb-6">
                            <img src="@/assets/images/about-company/our-advantages-4.png" class="card-img-top advantagesListImg"
                                alt="ПРЯМЫЕ АВТО">
                            <div class="card-body">
                                <div class="d-flex mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png"
                                        class="align-self-start mr-3 img-48" alt="СТРАХОВОЕ ПОКРЫТИЕ">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28 serviceBody">СТРАХОВОЕ<br>ПОКРЫТИЕ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0 mb-6">
                            <img src="@/assets/images/about-company/our-advantages-5.png" class="card-img-top advantagesListImg"
                                alt="ПРОВЕРЕННЫЕ АВТО">
                            <div class="card-body">
                                <div class="d-flex mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png"
                                        class="align-self-start mr-3 img-48" alt="ИНДИВИДУАЛЬНЫЙ ПОДХОД">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28 serviceBody">ИНДИВИДУАЛЬНЫЙ<br>ПОДХОД</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0 mb-6">
                            <img src="@/assets/images/about-company/our-advantages-6.png" class="card-img-top advantagesListImg"
                                alt="СЛЕЖЕНИЕ ГРУЗОВ">
                            <div class="card-body">
                                <div class="d-flex mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png"
                                        class="align-self-start mr-3 img-48" alt="ПРОЗРАЧНОСТЬ В БИЗНЕСЕ">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28 serviceBody">ПРОЗРАЧНОСТЬ<br>В БИЗНЕСЕ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h3 class="text-primary mt-3">Insurance is provided by KEL LLP and is reinsured (95%) by the Chubb
                        Group</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            companyInNumber: [
                {
                    title: "8 лет",
                    text: "на рынке международных грузовых автомобильных перевозок",
                },
                {
                    title: "Наша география",
                    text: "страны Европы, СНГ, Турция, Китай, перевозки по всему Казахстану",
                },
                {
                    title: "Свыше 100 Партнеров",
                    text: "доверяют нам услуги грузоперевозок",
                },
                {
                    title: "Свыше 15 000 рейсов",
                    text: "мы осуществили",
                },
                {
                    title: "свыше 300 000 тонн",
                    text: "различных грузов мы перевезли",
                },
            ],
            service: [
                "Все виды логистических услуг",
                "Все виды транспорта",
                "Весь мир"
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.advantagesListImg{
    max-width: 370px;
}
.advantagesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.advantages {
    display: flex;
    justify-content: space-between;
}

.serviceImg {
    max-width: 685px;
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

 
}
.serviceBody {
        font-weight: 400;
        align-items: center;
    }
.companyInNumberBg {
    background: #f2f2f2;

}

.companyInNumberTitle {
    font-weight: 400 !important;
}

.companyInNumberBody {
    display: flex;
}

.aboutCompanyInfo {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 500px;
}

.aboutCompany {
    display: flex;
    background: white;
}

.services,
.our-advantages,
.company-in-numbers {
    .title {
        font-size: var(--size-26);
    }

    .img-48 {
        width: 48px;
    }
}
</style>
