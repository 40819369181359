<template>
    <div class="container py-4">
        <div class="text-center mb-3">
            <h1 class="mb-4 page-title">Наши контакты</h1>
        </div>
        <div class="contactsInfo">
            <div class="d-flex flex-column justify-space-between pa-4">
                <div class="media pt-3 pt-lg-5 mb-4 ml-4">
                    <img src="@/assets/images/contacts/map-marker.png" class="align-self-center mr-3"
                        alt="РК, г. Алматы, ул. Майлина 85/2" style="width: 55px">
                    <div class="media-body ">
                        <h3 class="mt-1">РК, г. Алматы,<br>ул. Майлина 85/2</h3>
                    </div>
                </div>
                <div class="media pt-3 mb-4 ml-4">
                    <img src="@/assets/images/contacts/phone.png" class="align-self-center mr-3"
                        alt="+7 (727) 312-21-10" style="width: 55px">
                    <div class="media-body">
                        <h3 class="mt-2"><a href="tel:+77273122110" style="color: #212529;">+7 (727) 312-21-10</a>
                        </h3>
                    </div>
                </div>
                <div class="media pt-3 mb-4 ml-4">
                    <img src="@/assets/images/contacts/phone.png" class="align-self-center mr-3"
                        alt="+7 (771) 705-55-41" style="width: 55px">
                    <div class="media-body">
                        <h3 class="mt-2"><a href="tel:+77717055541" style="color: #212529;">+7 (771) 705-55-41</a>
                        </h3>
                    </div>
                </div>
                <div class="media pt-3 mb-4 ml-4">
                    <img src="@/assets/images/contacts/email.png" class="align-self-center mr-3" alt="info@kel.kz"
                        style="width: 55px">
                    <div class="media-body">
                        <h3 class="mt-2"><a href="mailto:info@kel.kz" style="color: #212529;">info@kel.kz</a></h3>
                    </div>
                </div>
                <div class="media pt-3 mb-4 ml-4">
                    <img src="@/assets/images/contacts/www.png" class="align-self-center mr-3" alt="www.kel.kz"
                        style="width: 55px">
                    <div class="media-body">
                        <h3 class="mt-2">www.kel.kz</h3>
                    </div>
                </div>
            </div>
            <img class="contactsImg" src="@/assets/images/contacts/1.png">
        </div>
    </div>
</template>

<script >
export default {
}
</script>

<style lang="scss" scoped>
.contactsImg {
    max-width: 800px;
}

.contactsInfo {
    display: flex;
    justify-content: space-between;
    background: white;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    .media{
        display: flex;
        align-items: center;
    }
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}
</style>
