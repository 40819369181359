<template>
    <div class="container py-4">
        <div class="text-center">
            <h1 class="page-title mb-4">Грузы <span class="text-muted">| ТОРГОВАЯ ПЛОЩАДКА</span></h1>
        </div>
        <div class="row mb-4">
            <div class="col-12 col-sm-6 col-md-9 d-none d-lg-block" v-if="!routeClassifier">
                <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
                Загрузка
            </div>
            <div class="col-12 col-sm-6 col-lg-9 d-none d-lg-block" v-if="routeClassifier">
                <strong v-for="routeClassifierItem in routeClassifier" :key="routeClassifierItem.routeClassifierId"
                    class="mr-3 search-item"
                    :class="{ 'search-item-active': (routeClassifierItem.routeClassifierId == routeClassifierId || (routeClassifierId == '' && routeClassifierItem.shortName == 'Все')) && !cargoLotId, 'text-muted': routeClassifierItem.count == 0, 'text-primary': routeClassifierItem.count > 0 }"
                    data-toggle="tooltip" data-html="true"
                    :title="'<b>' + routeClassifierItem.name + '</b><br>' + (routeClassifierItem.note != undefined ? routeClassifierItem.note : '')"
                    @click="selectRouteClassifierItem(routeClassifierItem.routeClassifierId, routeClassifierItem.shortName, routeClassifierItem.count)">{{
                        routeClassifierItem.shortName }} ({{ routeClassifierItem.count }})</strong>
            </div>
            <div class="d-flex align-center justify-space-between">
                <div class="col-6 col-sm-6 d-block d-lg-none" v-if="routeClassifier">
                    <v-select return-object class="sortSelect" v-model="sortByClassifier" variant="underlined"
                        :items="routeClassifier">
                        <template v-slot:item="{ props, item }">
                            <div @click="selectRouteClassifierItem(item.raw.routeClassifierId, item.raw.shortName, item.raw.count)"
                                class="slotSelect" v-bind="props">
                                <span>{{ item.raw.shortName }} ({{ item.raw.count }})</span>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span class="slotSelectName">{{ item.raw.shortName }} ({{ item.raw.count }})</span>
                        </template>
                    </v-select>
                </div>
                <div class="col-6 col-sm-6 d-block d-lg-none text-right" v-if="groupsCode">
                    <v-select return-object class="sortSelect" v-model="sortByGroups" variant="underlined"
                        :items="groupsCode">
                        <template v-slot:item="{ props, item }">
                            <div @click="selectGroupsCodeItem(item.raw.groupCode, item.raw.groupName, item.raw.cargoCount)"
                                class="slotSelect" v-bind="props">
                                <span>{{ item.raw.groupName }} ({{ item.raw.cargoCount }})</span>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span class="slotSelectName">{{ item.raw.groupName }} ({{ item.raw.cargoCount }})</span>
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="col-12 pt-3 d-none d-lg-block" v-if="!groupsCode">
                <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
                Загрузка
            </div>
            <div class="col-12 pt-3 d-none d-lg-block" v-if="groupsCode">
                <span v-for="groupsCodeItem in groupsCode" :key="groupsCodeItem.groupCode" class="mr-3 search-item"
                    :class="{ 'search-item-active': groupsCodeItem.groupCode == groupCode && !cargoLotId, 'text-muted': groupsCodeItem.cargoCount == 0, 'text-primary': groupsCodeItem.cargoCount > 0 }"
                    @click="selectGroupsCodeItem(groupsCodeItem.groupCode, groupsCodeItem.groupName, groupsCodeItem.cargoCount)">{{
                        groupsCodeItem.groupName }} ({{ groupsCodeItem.cargoCount }})</span>
            </div>
        </div>
        <div class="mb-3">
            <v-expansion-panels v-model="panel">
                <v-expansion-panel value="search">
                    <v-expansion-panel-title expand-icon="mdi-menu-down">
                        Расширенный поиск{{ isSetSearch ? ' (используется)' : '' }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-form @submit.prevent="onSubmitAdvancedSearch">
                            <div class="formSearchTop">
                                <v-select :items="searchLocalitys.items" v-model="advancedSearch.fromLocalityId"
                                    hide-details class="mr-6" label="Откуда"></v-select>
                                <v-select :items="searchLocalitys.items" v-model="advancedSearch.toLocalityId"
                                    hide-details label="Куда"></v-select>
                            </div>
                            <div class="formSearchTop">
                                <div class="formSearchFields">
                                    <span>Дата погрузки:</span>
                                    <v-text-field label="E-mail" hide-details></v-text-field>
                                </div>
                                <div class="formSearchFields">
                                    <span>Вид ТС:</span>
                                    <v-select item-title="rollingStockTypeName" item-value="rollingStockTypeId"
                                        :items="rollingStock.items" v-model="advancedSearch.rollingStockTypeId"
                                        hide-details></v-select>
                                </div>
                                <div class="formSearchFields">
                                    <span>Грузоподъемность (т.):</span>
                                    <div class="formItem">
                                        <v-text-field class="mr-2" label="от" hide-details></v-text-field>
                                        <v-text-field label="до" hide-details></v-text-field>
                                    </div>
                                </div>
                                <div class="formSearchFields">
                                    <span>Объем (куб.м):</span>
                                    <div class="formItem">
                                        <v-text-field class="mr-2" label="от" hide-details></v-text-field>
                                        <v-text-field label="до" hide-details></v-text-field>
                                    </div>
                                </div>
                                <div class="formSearchFields">
                                    <span>Номер перевозки:</span>
                                    <v-text-field hide-details></v-text-field>
                                </div>
                            </div>
                            <div class="expandBtnBody">
                                <v-btn class="mr-4" v-if="isSetSearch" color="#2c5799" variant="outlined"
                                    @click="SearchReset">Очистить всё</v-btn>
                                <v-btn type="submit" color="#2c5799" variant="tonal">Найти</v-btn>
                            </div>
                        </v-form>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="findedCard">
                <p>
                    Найдено: {{ cargosTotals }}
                </p>
            </div>
        </div>

        <div class="sortBody">
            <div class="mr-4">Сортировать по времени:</div>
            <v-select return-object class="sortSelect" variant="underlined" v-model="sortByModel"
                :items="sortByModelItems">
                <template v-slot:item="{ props, item }">
                    <div class="slotSelect" v-bind="props">
                        <i v-if="!item.raw.isBool" class="fas fa-sort-amount-up"></i>
                        <i v-else class="fas fa-sort-amount-down"></i>
                        <span class="ml-2">{{ item.raw.name }}</span>
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <span>
                        <i v-if="!item.raw.isBool" class="fas fa-sort-amount-up"></i>
                        <i v-else class="fas fa-sort-amount-down"></i>
                        <span class="ml-2 slotSelectName">{{ item.raw.name }}</span></span>
                </template>
            </v-select>
        </div>
        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
            <strong>Внимание!</strong> {{ errorMessage }}
        </div>
        <div v-if="!cargos" class="mb-3">
            <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
            Загрузка
        </div>
        <div v-else>
            <div v-if="cargos.totals == 0">
                <strong><i class="fas fa-search mr-2"></i>Ничего не найдено.</strong>
            </div>
            <div class="cargosCardList" v-if="cargos.totals > 0">
                <div class="cargosCard" v-for="cargoItem in cargos.items" :key="cargoItem.cargoLotId">
                    <div class="card h-100 shadow-sm">
                        <div class="bg-primary"
                            style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem;">
                            <div class="cardTop">
                                <div class="col-4 text-left text-white py-1">
                                    <small class="mb-2 pl-3">{{ cargoItem.cargoLotNumber }}</small>
                                </div>
                                <div class="col-4 text-center p-0">
                                    <small class="bg-white mb-2 px-2 pb-1"
                                        style="border-bottom-right-radius: 0.25rem; padding-top: 0.3rem; border-bottom-left-radius: 0.25rem;"><span
                                            v-html="getStatus(cargoItem)"></span></small>
                                </div>
                                <div class="col-4 text-right text-white py-1">
                                    <small class="mb-2 pr-3">{{ getByMoment(cargoItem.pickupDate) }}</small>
                                </div>
                            </div>
                            <div class="text-white px-3 pt-0 pb-2">
                                {{ cargoItem.cargo.descriptionOfCargo }}
                            </div>
                        </div>
                        <div class="cardBody">
                            <div class="d-flex align-center">
                                <div class="col-2 col-sm-1 mr-5">
                                    <i class="fas fa-map-marked-alt font-20 text-muted"></i>
                                </div>
                                <div class="col-10 col-sm-11">
                                    <span class="text-primary link-revert"
                                        @click="openRoute(cargoItem.cargoLotId, cargoItem.routeCaption)">{{
                                            cargoItem.routeCaption }}</span>
                                </div>
                            </div>
                            <div class="d-flex align-center mt-3">
                                <div class="col-2 col-sm-1 mr-5">
                                    <i class="fas fa-truck-moving font-20 text-muted"></i>
                                </div>
                                <div class="col-10 col-sm-11">
                                    {{ cargoItem.cargo.rollingStockRequestDisplay }}
                                </div>
                            </div>
                            <div class="d-flex align-center mt-3">
                                <div class="col-2 col-sm-1 mr-5">
                                    <i class="fas fa-exclamation-triangle font-20 text-warning"></i>
                                </div>
                                <div class="col-10 col-sm-11">
                                    Особые условия: {{ cargoItem.cargo.additionCarriageTerm }}
                                </div>
                            </div>
                        </div>
                        <div class="cardFooter">
                            <div class="mr-4">
                                <v-btn color="#2c5799" variant="outlined" size="small"
                                    @click="openOffers(cargoItem.cargoLotId)">
                                    ВСЕ ПРЕДЛОЖЕНИЯ
                                </v-btn>
                            </div>
                            <div class="col-6 col-sm-4 text-right text-sm-center">
                                <div class="mr-4" v-if="!cargoItem.agentOffer">
                                    <v-btn color="#2c5799" variant="outlined" @click="this.dialogAuth = true"
                                        size="small" v-if="!isLogged">
                                        <i class="fas fa-file-invoice-dollar font-18 mr-1"></i>
                                        ПРЕДЛОЖИТЬ ЦЕНУ
                                    </v-btn>
                                    <v-btn color="#2c5799" variant="outlined" size="small"
                                        @click="openAddOfferModal(cargoItem.cargoLotId, cargoItem.rollingStockTypeId, cargoItem)"
                                        v-else>
                                        <i class="fas fa-file-invoice-dollar font-18 mr-1"></i>ПРЕДЛОЖИТЬ
                                        ЦЕНУ
                                    </v-btn>
                                    <!-- <div class="modal fade" :id="'addOfferModal' + cargoItem.cargoLotId"
                                            tabindex="-1" :aria-labelledby="'addOfferModalLabel' + cargoItem.cargoLotId"
                                            aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header text-left">
                                                        <h5 class="modal-title"
                                                            :id="'addOfferModalLabel' + cargoItem.cargoLotId">Добавить
                                                            предложение: <strong>{{ cargoItem.cargoLotNumber
                                                                }}</strong></h5>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div v-if="errorMessageAddOffer" class="alert alert-danger mb-3"
                                                            role="alert">
                                                            <strong>Внимание!</strong> {{ errorMessageAddOffer }}
                                                        </div>
                                                        <DxForm :col-count="1" :form-data="offerNew"
                                                            :label-location="labelLocation"
                                                            :ref="'addOfferFormRef' + cargoItem.cargoLotId"
                                                            :show-colon-after-label="true"
                                                            :show-validation-summary="true" :disabled="isSendOffer">
                                                            <DxGroupItem :colCount="3">
                                                                <DxColCountByScreen :xs="1" :sm="1" />
                                                                <DxSimpleItem
                                                                    :editor-options="{ value: offerNew.rollingStockUnitTypeId, searchEnabled: true, dataSource: storeRollingStockUnitTypes, displayExpr: 'rollingStockUnitTypeName', valueExpr: 'rollingStockUnitTypeId', showClearButton: true }"
                                                                    data-field="rollingStockUnitTypeId"
                                                                    editor-type="dxSelectBox">
                                                                    <DxLabel text="Тип ПС" />
                                                                    <DxRequiredRule message="Укажите Тип ПС" />
                                                                </DxSimpleItem>
                                                                <DxSimpleItem
                                                                    :editor-options="{ value: offerNew.amount }"
                                                                    data-field="amount" editor-type="dxNumberBox">
                                                                    <DxLabel text="Ваше предложение" />
                                                                    <DxRequiredRule />
                                                                </DxSimpleItem>
                                                                <DxSimpleItem
                                                                    :editor-options="{ value: offerNew.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true }"
                                                                    data-field="currencyId" editor-type="dxSelectBox">
                                                                    <DxLabel text="Валюта" />
                                                                    <DxRequiredRule message="Укажите Валюту" />
                                                                </DxSimpleItem>
                                                            </DxGroupItem>
                                                            <DxSimpleItem :editor-options="{ value: offerNew.note }"
                                                                data-field="note" editor-type="dxTextBox">
                                                                <DxLabel text="Комментарий" />
                                                            </DxSimpleItem>
                                                        </DxForm>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-primary"
                                                            @click="AddOffer(cargoItem.cargoLotId)"
                                                            :disabled="isSendOffer">Сохранить
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                </div>
                                <div v-else>
                                    <v-btn color="#2c5799" variant="outlined"
                                        @click="openEditOfferModal(cargoItem.cargoLotId, cargoItem.rollingStockTypeId, cargoItem.agentOffer, cargoItem)"
                                        size="small" v-if="!isLogged">
                                        <i class="fas fa-file-invoice-dollar font-18 mr-1"></i>
                                        ИЗМЕНИТЬ ЦЕНУ
                                    </v-btn>
                                    <!-- <div class="modal fade" :id="'editOfferModal' + cargoItem.cargoLotId"
                                            tabindex="-1"
                                            :aria-labelledby="'editOfferModalLabel' + cargoItem.cargoLotId"
                                            aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header text-left">
                                                        <h5 class="modal-title"
                                                            :id="'editOfferModalLabel' + cargoItem.cargoLotId">
                                                            Изменить предложение: <strong>{{
                                                                cargoItem.cargoLotNumber }}</strong></h5>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div v-if="errorMessageEditOffer"
                                                            class="alert alert-danger mb-3" role="alert">
                                                            <strong>Внимание!</strong> {{ errorMessageEditOffer }}
                                                        </div>
                                                        <DxForm :col-count="1" :form-data="offerEdit"
                                                            :label-location="labelLocation"
                                                            :ref="'editOfferFormRef' + cargoItem.cargoLotId"
                                                            :show-colon-after-label="true"
                                                            :show-validation-summary="true" :disabled="isSendOffer">
                                                            <DxGroupItem :colCount="3">
                                                                <DxColCountByScreen :xs="1" :sm="1" />
                                                                <DxSimpleItem :editor-options="{
                                                                    value: offerEdit.rollingStockUnitTypeId,
                                                                    dataSource: storeRollingStockUnitTypes,
                                                                    displayExpr: 'rollingStockUnitTypeName',
                                                                    valueExpr: 'rollingStockUnitTypeId',
                                                                    showClearButton: true,
                                                                    showDataBeforeSearch: false
                                                                }" data-field="rollingStockUnitTypeId"
                                                                    editor-type="dxSelectBox">
                                                                    <DxLabel text="Тип ПС" />
                                                                    <DxRequiredRule message="Укажите Тип ПС" />
                                                                </DxSimpleItem>
                                                                <DxSimpleItem
                                                                    :editor-options="{ value: offerEdit.amount }"
                                                                    data-field="amount" editor-type="dxNumberBox">
                                                                    <DxLabel text="Новое предложение" />
                                                                    <DxRequiredRule />
                                                                </DxSimpleItem>
                                                                <DxSimpleItem
                                                                    :editor-options="{ value: offerEdit.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true }"
                                                                    data-field="currencyId" editor-type="dxSelectBox">
                                                                    <DxLabel text="Валюта" />
                                                                    <DxRequiredRule message="Укажите Валюту" />
                                                                </DxSimpleItem>
                                                            </DxGroupItem>
                                                            <DxSimpleItem :editor-options="{ value: offerEdit.note }"
                                                                data-field="note" editor-type="dxTextBox">
                                                                <DxLabel text="Комментарий" />
                                                            </DxSimpleItem>
                                                        </DxForm>
                                                    </div>
                                                    <div class="modal-footer d-flex justify-content-between">
                                                        <button type="button" class="btn btn-danger"
                                                            @click="DelOffer(cargoItem.cargoLotId, cargoItem.agentOffer.cargoOfferId)">
                                                            Удалить
                                                        </button>
                                                        <div>
                                                            <button type="button" class="btn btn-primary"
                                                                @click="EditOffer(cargoItem.cargoLotId, cargoItem.agentOffer.cargoOfferId)"
                                                                :disabled="isSendOffer">Сохранить
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                </div>
                            </div>
                            <div v-if="!cargoItem.agentOffer">
                                <span class="noOfferText">НЕТ ПРЕДЛОЖЕНИЯ</span>
                            </div>
                            <div v-else>
                                <span class="text-muted">{{ cargoItem.agentOffer.amount }} {{
                                    cargoItem.agentOffer.currencyShortName }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <v-pagination v-model="page" :length="cargos ? Math.ceil(cargos.totals / take) : 0"></v-pagination>
        </div>
    </div>
    <!-- Modals -->
    <v-dialog v-model="dialogAuth" width="auto">
        <v-card width="500">
            <div class="modal-header">
                <h5 class="modal-title" id="noLoginModalLabel">Вы не авторизованы</h5>
                <v-btn variant="text" @click="dialogAuth = false">
                    <span class="closeModalHeader" aria-hidden="true">&times;</span>
                </v-btn>
            </div>
            <div class="modal-body" v-if="!isCabinetDisabled">
                <div class="d-flex  justify-center mb-4">
                    Чтобы сделать/посмотреть предложение, вам нужно
                </div>
                <div class="text-center">
                    <v-btn color="#2c5799" text="войти" type="default" variant="outlined" @click="openLR('Login')"
                        class="my-3" />
                </div>
                <div class="text-center">
                    или
                </div>
                <div class="text-center">
                    <v-btn color="#2c5799" text="зарегистрироваться" type="default" variant="outlined"
                        @click="openLR('Registration')" class="my-3" />
                </div>
            </div>
            <div class="modal-body" v-else>
                <p>Для выполнения данных действий Вам требуется пройти регистрацию.</p>
                <p>По всем вопросам Вы можете связаться с нами по телефону:<br>
                    +7 (727) 312-21-10 или jivochat</p>
            </div>
            <div class="modal-footer">
                <v-btn @click="dialogAuth = false" color="#00afef" type="button">Закрыть</v-btn>
            </div>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCargo" width="auto">
        <v-card width="500">
            <div class="modal-header">
                <h5 class="modal-title">Информация по маршруту</h5>
                <v-btn variant="text" @click="dialogCargo = false">
                    <span class="closeModalHeader" aria-hidden="true">&times;</span>
                </v-btn>
            </div>
            <div class="modal-body">
                <strong>Маршрут:</strong>
                <p class="font-12 mb-4">{{ dialogCargoItem }}</p>
                <strong>Адреса:</strong>
                <div v-if="!selectRoute" class="mb-3">
                    <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
                    Загрузка
                </div>
                <table v-else class="table table-borderless font-12">
                    <tbody>
                        <tr v-for="(route, index) in selectRoute" :key="route.routePointId">
                            <td class="pb-0">
                                <div class="row">
                                    <div class="d-flex align-center mt-2 mb-2" v-if="index > 0">
                                        <i class="fas fa-long-arrow-alt-down text-yellow" style="font-size: 25px"></i>
                                        <strong class="text-primary ml-3" v-if="route.distance > 0">{{ route.distance }}
                                            км</strong>
                                    </div>
                                    <div class="addressItem">
                                        <div v-if="route.operationType == 'rptLoading'"
                                            class="h-100 d-flex align-items-center">
                                            <i class="fas fa-arrow-right pr-1 text-danger"
                                                style="font-size: 20px"></i><i class="fas fa-truck-loading text-danger"
                                                style="font-size: 25px"></i>
                                        </div>
                                        <div v-if="route.operationType == 'rtpUnloading'"
                                            class="h-100 d-flex align-items-center">
                                            <i class="fas fa-arrow-left pr-1 text-secondary"
                                                style="font-size: 20px"></i><i
                                                class="fas fa-truck-loading text-secondary" style="font-size: 25px"></i>
                                        </div>
                                        <div class="ml-6">
                                            <span v-if="isViewTime(route.operationTime)">{{
                                                route.operationTime ||
                                                moment("DD.MM.YYYY HH:mm") }}<br>{{
                                                    route.address.localityDisplay
                                                }}</span>
                                            <span v-if="!isViewTime(route.operationTime)">{{
                                                route.operationTime ||
                                                moment("DD.MM.YYYY") }}<br>{{
                                                    route.address.localityDisplay
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <v-btn @click="dialogCargo = false" color="#00afef" type="button">Закрыть</v-btn>
            </div>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogOffer" width="auto">
        <v-card width="600">
            <div class="modal-header">
                <h5 class="modal-title">
                    Предложения</h5>
                <v-btn variant="text" @click="dialogOffer = false">
                    <span class="closeModalHeader" aria-hidden="true">&times;</span>
                </v-btn>
            </div>
            <div class="modal-body">
                <div v-if="!selectOffers" class="mb-3">
                    <v-progress-circular :size="40" :width="7" color="primary" indeterminate></v-progress-circular>
                    Загрузка
                </div>
                <div v-if="selectOffers && selectOffers.length == 0">
                    <div class="row">
                        <div class="col-12">
                            На данный момент предложений нет.
                        </div>
                    </div>
                </div>
                <table v-if="selectOffers && selectOffers.length > 0" class="table mb-0 font-12">
                    <tbody>
                        <tr v-for="(offer, index) in selectOffers" :key="offer.cargoOfferId">
                            <td style="border: 0px; width: 5px;" class="pl-1 pr-0">
                                <i v-if="offer.status == 'oisApproved'" class="fas fa-check-circle text-success"></i>
                            </td>
                            <td style="border: 0px; width: 10px;" class="pr-0">{{
                                index + 1 }}:</td>
                            <td style="border: 0px; width: 130px">{{
                                offer.timeCreate || moment("DD.MM.YYYY HH:mm") }}
                            </td>
                            <td style="border: 0px">{{
                                offer.rollingStockUnitTypeName }}
                            </td>
                            <td class="text-right" style="border: 0px; width: 120px"><strong>{{
                                offer.amount }} {{ offer.currencyShortName }}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal-footer">
                <v-btn @click="dialogOffer = false" color="#00afef" type="button">Закрыть</v-btn>
            </div>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMakeOffer" width="auto">
        <v-card>
            <div class="modal-header">
                <h5 class="modal-title">
                    <span v-if="offerNew.type">Изменить</span>
                    <span v-else>Добавить</span>
                    предложение:
                    <strong>{{ offerNew.cargoItem.cargoLotNumber }}</strong>
                </h5>
                <v-btn variant="text" @click="dialogMakeOffer = false">
                    <span class="closeModalHeader" aria-hidden="true">&times;</span>
                </v-btn>
            </div>
            <div class="modal-body">
                <div v-if="errorMessageAddOffer" class="alert alert-danger mb-3" role="alert">
                    <strong>Внимание!</strong> {{ errorMessageAddOffer }}
                </div>
                <v-form :disabled="isSendOffer">
                    <v-card width="600">
                        <div class="modalItem">
                            <span class="mb-2">Тип ПС:</span>
                            <v-select item-title="rollingStockUnitTypeName" item-value="rollingStockUnitTypeId"
                                return-object v-model="offerNew.rollingStockUnitTypeId" :items="rollingStockUnitTypes">
                            </v-select>
                        </div>
                        <div class="modalItem">
                            <span class="mb-2">Ваше предложение:</span>
                            <v-text-field type="number" v-model="offerNew.amount"></v-text-field>
                        </div>
                        <div class="modalItem">
                            <span class="mb-2">Валюта:</span>
                            <v-select item-title="сurrencyName" item-value="currencyId" return-object
                                v-model="offerNew.currencyId" :items="currencys">
                            </v-select>
                        </div>
                        <div class="modalItem">
                            <span class="mb-2">Комментарий:</span>
                            <v-textarea v-model="offerNew.note"></v-textarea>
                        </div>
                    </v-card>
                </v-form>
                <!-- <DxForm :col-count="1" :form-data=" " :label-location="labelLocation"
                    :ref="'addOfferFormRef' + cargoItem.cargoLotId" :show-colon-after-label="true"
                    :show-validation-summary="true" :disabled="isSendOffer">
                    <DxGroupItem :colCount="3">
                        <DxColCountByScreen :xs="1" :sm="1" />
                        <DxSimpleItem
                            :editor-options="{ value: offerNew.rollingStockUnitTypeId, searchEnabled: true, dataSource: storeRollingStockUnitTypes, displayExpr: 'rollingStockUnitTypeName', valueExpr: 'rollingStockUnitTypeId', showClearButton: true }"
                            data-field="rollingStockUnitTypeId" editor-type="dxSelectBox">
                            <DxLabel text="Тип ПС" />
                            <DxRequiredRule message="Укажите Тип ПС" />
                        </DxSimpleItem>
                        <DxSimpleItem :editor-options="{ value: offerNew.amount }" data-field="amount"
                            editor-type="dxNumberBox">
                            <DxLabel text="Ваше предложение" />
                            <DxRequiredRule />
                        </DxSimpleItem>
                        <DxSimpleItem
                            :editor-options="{ value: offerNew.currencyId, items: currencys, displayExpr: 'сurrencyName', valueExpr: 'сurrencyId', showClearButton: true, searchEnabled: true }"
                            data-field="currencyId" editor-type="dxSelectBox">
                            <DxLabel text="Валюта" />
                            <DxRequiredRule message="Укажите Валюту" />
                        </DxSimpleItem>
                    </DxGroupItem>
                    <DxSimpleItem :editor-options="{ value: offerNew.note }" data-field="note" editor-type="dxTextBox">
                        <DxLabel text="Комментарий" />
                    </DxSimpleItem>
                </DxForm> -->
            </div>
            <div class="modal-footer">
                <v-btn v-if="offerNew.type == 1"
                    @click="DelOffer(offerNew.cargoItem.cargoLotId, offerNew.cargoItem.agentOffer.cargoOfferId)"
                    color="#e66454">
                    Удалить
                </v-btn>
                <v-btn
                    @click="offerNew.type == 1 ? EditOffer(offerNew.cargoItem.cargoLotId, offerNew.cargoItem.agentOffer.cargoOfferId) : AddOffer(offerNew.cargoItem.cargoLotId)"
                    :disabled="isSendOffer" color="#00afef">Сохранить</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';


export default {
    data() {
        return {
            isCabinetDisabled: true,
            // routeClassifier: null,
            sortByGroups: {
                groupCode: '',
                groupName: 'Выбрать группу',
                cargoCount: 0
            },
            sortByClassifier: {
                routeClassifierId: '',
                shortName: 'Все',
                count: 0
            },
            panel: [],
            sortByModel: {
                isBool: false,
                type: 'pickupDate',
                name: "подачи авто",
            },
            sortByModelItems: [
                {
                    isBool: false,
                    type: 'pickupDate',
                    name: "подачи авто",
                },
                {
                    isBool: true,
                    type: 'pickupDate',
                    name: "подачи авто",
                },
                {
                    isBool: false,
                    type: 'registrationTime',
                    name: "регистрации",
                },
                {
                    isBool: true,
                    type: 'registrationTime',
                    name: "регистрации",
                }
            ],
            dialogMakeOffer: false,
            // dialogMakeOfferData: false,
            dialogAuth: false,
            dialogOffer: false,
            dialogCargo: false,
            dialogCargoItem: null,
            routeClassifierId: '',
            routeClassifierName: "Все",
            routeClassifierCount: 0,
            // groupsCode: null,
            groupCode: '',
            groupCodeName: "Выбрать группу",
            groupCodeCount: 0,
            // currencys: null,
            // cargos: null,
            skeep: 0,
            take: 6,
            page: 1,
            orderField: "pickupDate",
            orderDesc: false,
            advancedSearch: {
                acn: "",
                fromLocalityId: "",
                toLocalityId: "",
                rollingStockTypeId: "",
                weightMin: "",
                weightMax: "",
                volumeMin: "",
                volumeMax: "",
                loadingType: "",
                pickupDate: ""
            },
            isSetSearch: false,
            offerNew: {
                type: 0,
                rollingStockUnitTypeId: "",
                amount: "",
                currencyId: "",
                cargoItem: null,
            },
            offerEdit: {
                rollingStockUnitTypeId: "",
                amount: "",
                currencyId: ""
            },
            // loadingTypes,
            labelLocation: 'top',
            buttonSearchOptions: {
                text: 'Найти',
                type: 'default',
                useSubmitBehavior: true
            },
            rollingStockTypeOptions: {
                value: this.advancedSearch?.rollingStockTypeId,
                searchEnabled: true,
                // dataSource: storeRollingStockTypes,
                displayExpr: 'rollingStockTypeName',
                valueExpr: 'rollingStockTypeId',
                showClearButton: true
            },
            storeRollingStockTypeId: "",
            isSendOffer: false,
            errorMessageAddOffer: "",
            errorMessageEditOffer: "",
            // selectRoute: null,
            // selectOffers: null,
            errorMessage: "",
            cargosTotals: 0,
            cargoLotId: this.$route.params.cargoLotId ? this.$route.params.cargoLotId : ''
        }
    },
    mounted() {
        this.getRouteClassifier();
        this.getCurrencys();
        //this.getCargos();
    },
    methods: {
        getByMoment(date) {
            return moment(date).format("DD.MM.YYYY")
        },
        getRouteClassifier() {
            this.$store.dispatch('getRouteClassifier').then(() => {
                // this.routeClassifier = localStore.state.routeClassifier;

                this.routeClassifierId = this.routeClassifier[0].routeClassifierId;
                this.routeClassifierName = this.routeClassifier[0].shortName;
                this.routeClassifierCount = this.routeClassifier[0].count;

                this.getGroupsCode(this.routeClassifierId);

            });
        },
        getGroupsCode(routeClassifierId, tokenId = '') {
            if (this.Agent) {
                tokenId = this.Agent.tokenId;
            }

            this.$store.dispatch('getGroupsCode', { tokenId, routeClassifierId }).then(() => {
                // this.groupsCode = localStore.state.groupsCode;
                let set_first = true;

                this.groupsCode.forEach(function (itemGroupCode) {
                    if (this.groupCode == itemGroupCode.groupCode) {
                        set_first = false;
                        this.groupCode = itemGroupCode.groupCode;
                        this.groupCodeName = itemGroupCode.groupName;
                        this.groupCodeCount = itemGroupCode.cargoCount;
                    }
                }, this);

                if (set_first) {
                    this.groupCode = this.groupsCode[0].groupCode;
                    this.groupCodeName = this.groupsCode[0].groupName;
                    this.groupCodeCount = this.groupsCode[0].cargoCount;
                    this.sortByGroups = {
                        groupCode: this.groupsCode[0].groupCode,
                        groupName: this.groupsCode[0].groupName,
                        cargoCount: this.groupsCode[0].cargoCount
                    }
                }

                this.getCargos();
            });
        },
        getCurrencys() {
            this.$store.dispatch('getCurrencys').then(() => {
                // this.currencys = localStore.state.currencys;
            });
        },
        getCargos(
            tokenId = '',
            routeClassifierId = this.routeClassifierId,
            order_field = this.orderField,
            order_desc = this.orderDesc,
            skeep = this.skeep,
            take = this.take,
            advancedSearch = this.advancedSearch,
            groupCode = this.groupCode
        ) {
            // $('html, body').animate({scrollTop:0}, '300');

            // this.cargos = null
            this.cargosTotals = 0;
            this.errorMessage = "";
            let cargoLotId = this.cargoLotId;

            if (this.Agent) {
                tokenId = this.Agent.tokenId;
            }

            this.$store.dispatch('getCargos', {
                tokenId,
                routeClassifierId,
                order_field,
                order_desc,
                skeep,
                take,
                advancedSearch,
                groupCode,
                cargoLotId
            }).then(() => {
                // this.cargos = localStore.state.cargos;
                this.cargosTotals = this.cargos != null ? this.cargos.totals : 0;
            }).catch((e) => {
                console.log(e)
            });
        },
        // handleErrorCargos: function (error) {
        //     if (error.response.status === 400) {
        //         if(error.response.data) {
        //             let errorMessage = error.response.data;

        //             this.errorMessage = errorMessage;
        //         } else {
        //             this.errorMessage = "Ошибка при поиске лотов. Попробуйте еще раз.";
        //         }
        //     } else if(error.response.status === 500) {
        //         if(error.response.data) {
        //             let errorMessage = error.response.data;

        //             this.errorMessage = JSON.stringify(errorMessage);
        //         } else {
        //             this.errorMessage = "Ошибка при поиске лотов. Попробуйте еще раз.";
        //         }
        //     } else {
        //         this.errorMessage = "Ошибка при поиске лотов. Попробуйте еще раз.";
        //     }

        //     this.cargos = [];
        //     console.error(error);
        // },
        getStatus(cargoItem) {
            switch (cargoItem.status) {
                case 'lotActive':
                    return '<i class="fas fa-hand-holding-usd text-success mr-1"></i>Активная';
                case 'lotRejected':
                    return '<i class="fas fa-times-circle text-danger mr-1"></i>Аннулирована';
                case 'lotProcessing':
                    return '<i class="fas fa-eye text-secondary mr-1"></i>На рассмотрении';
                case 'lotApproved':
                    return '<i class="fas fa-check-square text-success mr-1"></i>Одобрена';
                case 'lotOfferRejected':
                    return '<i class="fas fa-ban text-danger mr-1"></i>Отклонена';
                case 'lotClosed':
                    return '<i class="fas fa-lock text-danger mr-1"></i>Закрыта';
            }
            return '- - -';
        },
        selectRouteClassifierItem(routeClassifierId, routeClassifierName, routeClassifierCount) {
            this.routeClassifierId = routeClassifierId;
            this.routeClassifierName = routeClassifierName;
            this.routeClassifierCount = routeClassifierCount;
            this.sortByClassifier = {
                routeClassifierId: routeClassifierId,
                shortName: routeClassifierName,
                count: routeClassifierCount
            }
            if (this.$route.params.cargoLotId) {
                this.cargoLotId = '';
                this.$router.replace({ path: '/marketplace/cargos' });
            }
            this.resetParameters();
            this.getGroupsCode(this.routeClassifierId);
        },
        selectGroupsCodeItem(groupCode, groupName, cargoCount) {
            this.groupCode = groupCode;
            this.groupCodeName = groupName;
            this.groupCodeCount = cargoCount;
            this.sortByGroups = {
                groupCode: groupCode,
                groupName: groupName,
                cargoCount: cargoCount
            }
            this.page = 1;
            this.skeep = 0;
            if (this.$route.params.cargoLotId) {
                this.cargoLotId = '';
                this.$router.replace({ path: '/marketplace/cargos' });
            }
            this.getCargos();
        },
        sortBy(orderField, orderDesc) {
            this.orderField = orderField;
            this.orderDesc = orderDesc;

            this.getCargos();
        },
        openRoute(cargoLotId, routeName, tokenId = '') {
            // this.selectRoute = null;

            if (this.Agent) {
                tokenId = this.Agent.tokenId;
            }

            this.$store.dispatch('getRoute', {
                tokenId,
                cargoLotId
            }).then(() => {
                this.dialogCargo = true;
                this.dialogCargoItem = routeName
                // this.selectRoute = localStore.state.route;
            });

            // $('#routeModal' + cargoLotId).modal('toggle');
        },
        openOffers(cargoLotId, tokenId = '') {
            this.selectOffers = null;

            if (this.Agent) {
                tokenId = this.Agent.tokenId;
            }

            this.$store.dispatch('getOffers', {
                tokenId,
                cargoLotId
            }).then(() => {
                this.dialogOffer = true;
                // this.selectOffers = localStore.state.offers;
            });

            // $('#offersModal' + cargoLotId).modal('toggle');
        },
        openLR(nameRoute) {
            // $('#noLoginModal').modal('toggle');
            this.$router.push({ name: nameRoute, query: { redirect: this.$route.path } });
        },
        nextPage() {
            this.skeep = this.skeep + this.take;
            this.getCargos();
        },
        prevPage() {
            this.skeep = this.skeep - this.take;
            if (this.skeep < 0) {
                this.skeep = 0;
            }
            this.getCargos();
        },
        onSubmitAdvancedSearch() {
            if (this.advancedSearch.acn ||
                this.advancedSearch.fromLocalityId ||
                this.advancedSearch.toLocalityId ||
                this.advancedSearch.rollingStockTypeId ||
                this.advancedSearch.weightMin ||
                this.advancedSearch.weightMax ||
                this.advancedSearch.volumeMin ||
                this.advancedSearch.volumeMax ||
                this.advancedSearch.loadingType ||
                this.advancedSearch.pickupDate
            ) {
                this.isSetSearch = true;
            } else {
                this.isSetSearch = false;
            }

            if (this.$route.params.cargoLotId) {
                this.cargoLotId = '';
                this.$router.replace({ path: '/marketplace/cargos' });
            }

            this.page = 1;
            this.skeep = 0;
            this.getCargos();
        },
        SearchReset() {
            this.advancedSearch = {
                acn: "",
                fromLocalityId: "",
                toLocalityId: "",
                rollingStockTypeId: "",
                weightMin: "",
                weightMax: "",
                volumeMin: "",
                volumeMax: "",
                loadingType: "",
                pickupDate: ""
            };

            if (this.$route.params.cargoLotId) {
                this.cargoLotId = '';
                this.$router.replace({ path: '/marketplace/cargos' });
            }

            this.isSetSearch = false;
            this.page = 1;
            this.skeep = 0;
            this.getCargos();
        },
        resetParameters() {
            this.page = 1;
            this.skeep = 0;
            this.groupsCode = null;
        },
        isViewTime(pickupDate) {
            return moment(pickupDate).format('HH:mm') != '00:00';
        },
        openAddOfferModal(cargoLotId, rollingStockTypeId, cargoItem) {
            this.storeRollingStockTypeId = rollingStockTypeId;
            this.errorMessageAddOffer = "";
            // this.$refs['addOfferFormRef' + cargoLotId][0].instance.resetValues();
            this.isSendOffer = false;
            this.dialogMakeOffer = true;
            // this.dialogMakeOfferData = cargoItem.cargoLotNumber;
            this.offerNew = {
                cargoItem: cargoItem
            }
            this.$store.dispatch('getRollingStockUnitTypesApi', { storeRollingStockTypeId: rollingStockTypeId })
        },
        AddOffer(cargoLotId) {
            this.isSendOffer = true;
            this.errorMessageAddOffer = "";
            let result = this.$refs['addOfferFormRef' + cargoLotId][0].instance.validate();

            if (result.isValid) {
                const { rollingStockUnitTypeId, amount, currencyId, note } = this.offerNew;

                this.$store.dispatch('sendOffer', { rollingStockUnitTypeId, amount, currencyId, note, cargoLotId }).then(() => {
                    this.getCargos();
                    this.dialogMakeOffer = false;
                    this.errorMessageAddOffer = "";
                }).catch((error) => {
                    if (error.response.status === 400) {
                        if (error.response.data) {
                            let errorMessage = error.response.data;

                            this.errorMessageAddOffer = errorMessage;
                        } else {
                            this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                        }
                    } else {
                        this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
                    }
                    this.isSendOffer = false;
                    console.error(error);
                }).finally(() => {
                    this.dialogMakeOffer = false;
                })
                // .catch(this.handleErrorAddOffer);
            } else {
                this.isSendOffer = false;
            }
        },

        //     handleErrorAddOffer: function (error) {
        //         if (error.response.status === 400) {
        //             if(error.response.data) {
        //                 let errorMessage = error.response.data;

        //                 this.errorMessageAddOffer = errorMessage;
        //             } else {
        //                 this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
        //             }
        //         } else {
        //             this.errorMessageAddOffer = "Ошибка при подаче предложения. Проверьте указанные вами данные и попробуйте еще раз.";
        //         }

        //         this.isSendOffer = false;
        //         console.error(error);
        //     },
        openEditOfferModal(cargoLotId, rollingStockTypeId, agentOffer, cargoItem) {
            this.storeRollingStockTypeId = rollingStockTypeId;
            this.errorMessageEditOffer = "";
            //let dxsel = this.$refs['editOfferFormRef'+cargoLotId][0].instance.getEditor("rollingStockUnitTypeId");
            // window.rollingStockUnitTypeId = agentOffer.rollingStockUnitTypeId;
            // window.rollingStockUnitTypeName = agentOffer.rollingStockUnitTypeName;
            // this.dialogMakeOfferData = cargoItem.cargoLotNumber;
            this.offerNew = {
                rollingStockUnitTypeId: agentOffer.rollingStockUnitTypeId,
                amount: agentOffer.amount,
                currencyId: agentOffer.currencyId,
                note: agentOffer.note,
                cargoItem: cargoItem,
                type: 1
            };
            this.dialogMakeOffer = true;
            this.isSendOffer = false;
            // $('#editOfferModal' + cargoLotId).modal('toggle');
        },
        EditOffer(cargoLotId, cargoOfferId) {
            this.isSendOffer = true;
            this.errorMessageEditOffer = "";
            let result = this.$refs['editOfferFormRef' + cargoLotId][0].instance.validate();

            if (result.isValid) {
                const { rollingStockUnitTypeId, amount, currencyId, note } = this.offerEdit;

                this.$store.dispatch("sendOffer", {
                    cargoOfferId: cargoOfferId,
                    rollingStockUnitTypeId: rollingStockUnitTypeId,
                    amount: amount,
                    currencyId: currencyId,
                    note: note
                }).then(() => {
                    this.getCargos();
                    this.dialogMakeOffer = false;
                    // $('#editOfferModal' + cargoLotId).modal('toggle');
                    this.errorMessageEditOffer = "";
                })
                // .catch(this.handleErrorEditOffer);
            } else {
                this.isSendOffer = false;
            }
        },
        //     handleErrorEditOffer: function (error) {
        //         if (error.response.status === 400) {
        //             if(error.response.data) {
        //                 let errorMessage = error.response.data;

        //                 this.errorMessageEditOffer = errorMessage;
        //             } else {
        //                 this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
        //             }
        //         } else {
        //             this.errorMessageEditOffer = "Ошибка при изменении предложения. Проверьте указанные вами данные и попробуйте еще раз.";
        //         }

        //         this.isSendOffer = false;
        //         console.error(error);
        //     },
        DelOffer(cargoLotId, cargoOfferId) {
            this.$store.dispatch('delOffer', { cargoLotId, cargoOfferId }).then(() => {
                this.getCargos();
                this.dialogMakeOffer = false;
                // $('#editOfferModal' + cargoLotId).modal('toggle');
                this.errorMessageEditOffer = "";
            })
            // .catch(this.handleErrorDelOffer);
        },
        //     handleErrorDelOffer: function (error) {
        //         if (error.response.status === 400) {
        //             if(error.response.data) {
        //                 let errorMessage = error.response.data;

        //                 this.errorMessageEditOffer = errorMessage;
        //             } else {
        //                 this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
        //             }
        //         } else {
        //             this.errorMessageEditOffer = "Ошибка при удалении предложения. Попробуйте еще раз.";
        //         }

        //         this.isSendOffer = false;
        //         console.error(error);
        //     },

    },
    computed: {
        ...mapGetters({
            routeClassifier: 'getRouteClassifier',
            groupsCode: 'getGroupsCode',
            cargos: 'getCargos',
            selectOffers: 'getOffers',
            selectRoute: 'getRoute',
            currencys: 'getCurrencys',
            isLogged: 'isLogged',
            searchLocalitys: 'getSearchLocalitys',
            rollingStock: 'getRollingStock',
            rollingStockUnitTypes: 'getRollingStockUnitTypes'
        })
    },
    watch: {
        sortByModel: function (item) {
            this.sortBy(item.type, item.isBool)
        },
        page: function (pageNum) {
            this.skeep = (pageNum - 1) * this.take;
            this.getCargos();
        },
        panel: function () {
            if (!this.searchLocalitys.length) {
                this.$store.dispatch('getSearchDataApi', '')
            }
            if (!this.rollingStock.length) {
                this.$store.dispatch('getRollingStockApi', '')
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.modalItem {
    display: flex;
    flex-direction: column
}

.expandBtnBody {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
}

.formSearchTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .formSearchFields {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 8px;
    }

    .formItem {
        width: 100%;
        display: flex;
        align-items: center;
    }
}

.cargosCard {
    margin-bottom: 24px;
    max-width: 580px;
    width: 100%;
}

.noOfferText {
    font-size: 12px;
}

.cargosCardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.cardBody {
    padding: 16px;
    background: white;
}

.sortSelect {
    max-width: 200px;
}

.slotSelectName {
    color: #2c5799 !important;
    font-weight: 700;
}

.slotSelect {
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 8px;
    color: #2c5799;

    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.05);
    }

}

.sortBody {
    display: flex;
    align-items: center;

}

.findedCard {
    display: flex;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    color: #6c757d !important;
    font-size: 12px;
    padding: 8px 12px;
}

.addressItem {
    display: flex;
    align-items: center;

}

.modal-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.modal-footer {
    border-top: 1px solid #dee2e6;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #dee2e6;

    .modal-title {
        font-size: 18px;
    }

    .closeModalHeader {
        font-size: 28px;
    }
}

.advanced-search {
    display: none;
}
</style>
