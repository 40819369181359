<template>
  <div class="vApp">
    <v-app>
      <TopbarComponent :routes="getRoutesByRouteName($route.name)"></TopbarComponent>
      <div class="routerView">
        <router-view></router-view>
      </div>
      <FooterComponent></FooterComponent>
      <notifications />
    </v-app>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
import 'vue-material-design-icons/styles.css';
import TopbarComponent from "./components/TopbarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
export default {
  name: "App",
  components: {
    TopbarComponent,
    FooterComponent,
  },
  methods: {
    getRoutesByRouteName(name) {
      switch (name) {
        case 'Home':
          return [
            {
              id: 1,
              name: "Home",
              title: "Новые грузы",
              hash: "#online-orders",
            },
            {
              id: 2,
              name: "Home",
              title: "КЕЛ в цифрах",
              hash: "#company-in-numbers",
            },
          ];
        case "AboutCompany":
          return [
            {
              id: 1,
              name: 'AboutCompany',
              title: 'КЕЛ в цифрах',
              hash: '#company-in-numbers'
            },
            {
              id: 2,
              name: 'AboutCompany',
              title: 'Услуги',
              hash: '#services'
            },
            {
              id: 3,
              name: 'AboutCompany',
              title: 'Преимущества',
              hash: '#our-advantages'
            },
          ]
        case 'Partners':
          return [
            {
              id: 1,
              name: 'Partners',
              title: 'Партнеры',
              hash: ''
            }
          ]
        case 'CareerCompany':
          return [
            {
              id: 1,
              name: 'CareerCompany',
              title: 'Карьера',
              hash: '#career'
            },
            {
              id: 2,
              name: 'CareerCompany',
              title: 'Вакансии',
              hash: '#vacancies'
            }
          ]
        case 'Contacts':
          return [
            {
              id: 1,
              name: 'Contacts',
              title: 'Контакты',
              hash: ''
            }
          ]
        case 'Marketplace':
          return [
            {
              id: 1,
              name: 'Cargos',
              title: 'Грузы',
              hash: ''
            }
          ]
      }

    },
  },
};
</script>

<style>
.routerView{
  height: 100%;
}
.v-application__wrap {
  background: #f2f2f2;

}
</style>
