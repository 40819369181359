<template>
    <div class="fixedFooter">
        <div class="footer">
            <div class="container">
                <div class="footer-nav">
                    <div class="footerBody">
                        <div class="footerItem d-none d-md-flex">
                            <div class="colTitle">Полезное</div>
                            <div class="routerLinks" @click="$router.push({ name: item.to, hash: item.hash ? item.hash : '' })"
                                v-for="(item, index) in usefulRoute" :key="index + 'usefulRoute'">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="footerItem d-none d-md-flex">
                            <div class="colTitle colTitleRoute"
                                @click="$router.push({ name: 'AboutCompany', hash: '#about-company' })">О компании</div>
                            <div class="routerLinks" @click="$router.push({ name: item.to, hash: item.hash ? item.hash : '' })"
                                v-for="(item, index) in routerLinks" :key="index + 'routerLinks'">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="footerItem ">
                            <address><span class="d-block mb-2">Республика Казахстан,</span><span
                                    class="d-block mb-2">г. Алматы,</span><span class="d-block">ул. Майлина
                                    85/2</span></address>
                        </div>
                        <div class="footerItem">
                            <a href="tel:+77273122110" class="phone d-block mb-2">+7 (727) 312-21-10</a>
                            <a href="tel:+77717055541" class="phone d-block mb-2">+7 (771) 705-55-41</a>
                            <a href="mailto:info@kel.kz" class="mail link-ul">info@kel.kz</a>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center">
                            <div>
                                <img src="@/assets/images/logo-notext.svg">
                            </div>
                            <div class="flex-fill text-right">
                                © {{ new Date().getFullYear() }} KEL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-tricolor d-flex">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            usefulRoute: [
                {
                    to: "Marketplace",
                    title: "Тендер",
                },
                {
                    to: "Partners",
                    title: "Партнеры",
                },
                {
                    to: "CareerCompany",
                    hash: '#vacancies',
                    title: "Вакансии",
                },
                {
                    to: "Login",
                    title: "Вход",
                },
                {
                    to: "Registration",
                    title: "Регистрация",
                },
            ],
            routerLinks: [
                {
                    to: "AboutCompany",
                    hash: '#company-in-numbers',
                    title: "Компания в цифрах",
                },
                {
                    to: "AboutCompany",
                    hash: '#services',
                    title: "Услуги",
                },
                {
                    to: "Contacts",
                    title: "Контакты",
                },
                {
                    to: "AgreementsUser",
                    title: "Пользовательское соглашение",
                },
                {
                    to: "AgreementsPrivacy",
                    title: "Политика конфиденциальности",
                },
            ],
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fixedFooter{
}
.routerLinks {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.colTitleRoute {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.colTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
}

.footerBody {
    display: flex;
    justify-content: space-between;

    .footerItem {
        display: flex;
        flex-direction: column !important;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;

        .footerItem {
            margin-bottom: 16px;
        }
    }
}

.footer {
    color: #fff;
    position: relative;
    z-index: 1;
    background-color: var(--color-primary);
}

.footer a {
    color: #fff;
}

.footer-nav {
    padding-top: 50px;
    padding-bottom: 25px;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 600px) {
        padding-top: 0px;


    }

    .nav-col .col-title {
        margin-bottom: .75rem;
        font-size: var(--size-20);
    }

    .nav-col ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .nav-col ul li {
        margin-bottom: .5rem;
    }

    .nav-col ul li a {
        font-weight: 300;
    }
}

.footer-nav address {
    font-size: var(--size-22);
    color: #FFCC29;
}

.footer-nav .phone {
    font-size: var(--size-22);
    color: #FFCC29;
}

.footer-nav .mail {
    font-size: var(--size-22);
    font-weight: 300;
    color: #FFCC29;
}

.footer-bottom {
    padding: 30px 0;
    font-weight: 300;
    font-size: var(--size-14);
    border-top: 1px solid rgba(255, 255, 255, .25);
}

.footer-tricolor {
    >div {
        height: 8px;
        width: 33.33333333%;
    }

    >div:nth-child(1) {
        background: #214b8d;
    }

    >div:nth-child(2) {
        background: var(--color-yellow);
    }

    >div:nth-child(3) {
        background-color: var(--color-secondary);
    }
}
</style>
