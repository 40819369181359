<template>
    <div class="container py-4">
        <div class="text-center mb-3">
            <h1 class="mb-4 page-title">Наши партнеры</h1>
        </div>
        <div class="partnersList">
            <div class="partnersImg">
                <img src="@/assets/images/partners/1.png" class="rounded w-75">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/2.png" class="rounded w-75">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/3.png" class="rounded w-40">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/4.png" class="rounded w-75">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/9.svg" class="rounded w-50">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/5.png" class="rounded w-45">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/6.png" class="rounded w-50">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/7.png" class="rounded w-50">
            </div>
            <div class="partnersImg">
                <img src="@/assets/images/partners/8.png" class="rounded w-50">
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.partnersImg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin-bottom: 16px;
    
}
.partnersList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}
</style>
