import { createRouter, createWebHistory } from "vue-router";

import HomeScreen from "../screen/HomeScreen.vue";
import AboutScreen from "../screen/AboutScreen.vue";
import PartnersScreen from "../screen/PartnersScreen.vue";
import CareerCompanyScreen from "../screen/CareerCompanyScreen.vue";
import ConsolidationScreen from "../screen/ConsolidationScreen.vue";
import ContactsScreen from "../screen/ContactsScreen.vue";
import MarketplaceScreen from "../screen/Marketplace/CargoScreen.vue";

const routes = [
  {
    name: "Home",
    path: "/",
    component: HomeScreen,
  },
  {
    name: "Partners",
    path: "/partners",
    component: PartnersScreen,
  },
  {
    name: "Contacts",
    path: "/contacts",
    component: ContactsScreen,
  },
  {
    name: "AboutCompany",
    path: "/about-company",
    component: AboutScreen,
  },
  {
    name: "CareerCompany",
    path: "/career-company",
    component: CareerCompanyScreen,
  },
  {
    name: "Consolidation",
    path: "/consolidation",
    component: ConsolidationScreen,
  },
  {
    path: "/marketplace",
    children: [
      {
        name: "Marketplace",
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "",
        component: MarketplaceScreen,
      },
      //   {
      //     name: 'CargosLotId',
      //     path: '/marketplace/cargos/:cargoLotId',
      //     component: () => import('../components/marketplace/Cargos.vue'),
      //   },
    ],
  },
  // ...cabinetRoutes,
  // {
  //     name: 'Error404',
  //     path: '*',
  //     component: () => { <View>Error 404</View> },
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
